/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import testlogo2 from "../../images/testlogo2.png"
import profile from "../../images/profile.gif"
import { Link } from "react-router-dom"
import { login } from "../../redux/actions/auth"




const Nav_logged_in = () => {
  
    const dispatch = useDispatch()
    const history = useHistory()
    const auth = useSelector(state => state.auth)

    function logOut(params) {
       const token = localStorage.getItem('accessToken')
       if(typeof token !== 'undefined') {
           localStorage.setItem('accessToken', '')
           history.push('/')
           dispatch(login({ accessToken: '' }))
       } 
       
       history.push('/')
       dispatch(login({ accessToken: '' }))
    }

    return (


<div className="fixed-top">

<div className="bac-mobile">

<div className="container">
    <div className="row">
        <div className="col-lg-12">
            <Link to="/">
            <img src={testlogo2} className="img-fluid logo-nav-2" alt="logo"/>
            </Link>
        
  <img src={ auth.user  && auth.user.avatar ? auth.user.avatar.thumb : profile} alt='profile-avatar' className="img-fluid profile-log" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"/>
  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <Link to="/dashboard">
            <li className="dropdown-item">My Dashboard</li>
            </Link>
            <Link onClick={() => logOut()}>
            <li className="dropdown-item">Logout</li>
            </Link>
          </ul>  
          
            
        </div>
    </div>
</div>

</div>



{/* main closing div */}
</div>
    );

}

export default Nav_logged_in;
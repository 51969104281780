import React from "react";
import { Link } from "react-router-dom";


const Footer = () => (


<div>

   
    <div className="container-fluid">

<div className="row">
    <div className="col-md-3">
        <h3 className="footer-head">ABOUT</h3>
        <Link to="/why">
        <li className="fot">Why us?</li>
        </Link>
        <Link to="/careers">
        <li className="fot">Careers</li>
        </Link>
        <li className="fot">Pricing</li>

    </div>

    <div className="col-md-3">
        <h3 className="footer-head">COMMUNITY</h3>
        {/*
        <li className="fot">Blog</li>
        */}
        <Link to="/invite">
        <li className="fot">Invite friends</li>
        </Link>
       
        <Link to="/privacy">
        <li className="fot">Privacy</li>
        </Link>
   
    </div>


    <div className="col-md-3">
        <h3 className="footer-head">DEALERS</h3>
        {/*
        <Link to="/signup">
        <li className="fot">Create an Account</li>
        </Link>
        */}
        <Link to="/how">
        <li className="fot">Getting started</li>
        </Link>
        <Link to="/privacy">
        <li className="fot">Terms</li>
        </Link>

    </div>


    <div className="col-md-3">
        <h3 className="footer-head">SUPPORT</h3>
        <Link to="/help">
        <li className="fot">Help center</li>
        </Link>
        <Link to="/contact">
        <li className="fot">Contact</li>
        </Link>
        
    </div>


    
</div>

    </div>


    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="line"></div>
                <p className="copyright">© 2021 MaineAutoparts, Ltd. | All rights reserved.</p>
            </div>
        </div>
    </div>


   


{/* main closing div */}
</div>


    );

export default Footer ;

import React, { useState } from "react"
import { useDispatch } from 'react-redux'
import { Link, useHistory } from "react-router-dom"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import { startLogin } from "../../redux/actions/auth"

const Form_login = (props) => {
  
    const history = useHistory()
    const dispatch = useDispatch()
    const [alertMessage, setAlertMessage] = useState({ type: 'error', message: ''})

    const validationSchema = yup.object({
    email: yup.string().trim().email('Invalid email address').required('Email address is required'),
    password: yup.string().trim().required('Password is required').min(6, 'Your password must be at least 6 characters long'),
});



return (


<div>
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="card-sign-up-2">
                    <h3 className="form-title">Sign into an account</h3>
                    
                    {
                        alertMessage && alertMessage.type === 'error'
                        &&
                        <p style={{ color: 'red', textAlign:'center'}} >{alertMessage.message}</p>
                    }
                    <Formik     
                    initialValues={{ email: '', password: '' }}
                    validationSchema={validationSchema}
                    onSubmit={
                       async (values, actions) => {

                         setAlertMessage({ type: 'error', message: ''})

                        try {
                          const result = await dispatch(startLogin(
                              {
                                 email: values.email,
                                 password: values.password
                              }
                          ))

                          if(result) {
                  
                              localStorage.setItem('access_token', result.auth.accessToken);
                              localStorage.setItem('user', JSON.stringify(result.auth.user));
                            //   push to dashboard
                            // not to be sent to the login page but an open endroute for auth

                            // window.location.replace(`http://maineautoparts.com/admin`)

                            // route the use
                            history.push('/dashboard')
                            // window.location.replace(`http://dashboard.maineautoparts.com/login?token=${result.auth.accessToken}`)
                          }

                        } catch (error) {
                            // stop at the gate
                            switch (error.response.status) {
                                case 401:
                                    setAlertMessage({type: 'error', message: error.response.data.message})
                                    break;
                            
                                default:
                                    break;
                            }
                        }
                         
                        }
                    }
                    >
                     {
                         props => (
                            <Form onSubmit={props.handleSubmit}>
                            <Field type="email"  name='email' className="overide input-email" placeholder="Email address e.g john@gmail.com"/>
                            <ErrorMessage name='email' component='div' className="error-handlers"/>
                            <Field type="password" name='password' className="overide input-password" placeholder="Enter password"/>
                            <ErrorMessage name='password' component='div' className="error-handlers" />
                            <button type='submit' disabled={props.isSubmitting} className="sign-up-btn">{props.isSubmitting ? 'Logging in...' : 'Log me in'}</button>
                            <h3 className="form-title"><Link to="/forgot_password" className="forgot-link">Forgot password?</Link></h3>
                            </Form>
                         )
                     } 
                    </Formik>

                </div>
                <p className="terms-form">Read our <span className="tt"><Link to="/privacy" className="aa-colo-2">Terms of service </Link></span></p>
            </div>
        </div>
    </div>


    {/* main closing div */}
    </div>


    );
}

export default Form_login ;

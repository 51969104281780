import React, { useEffect } from "react";
import Footer from "../partials/footer";
import careers from "../../images/careers.jpg";
import { Link } from "react-router-dom";
import testlogo2 from "../../images/testlogo2.png";


const Careers = () => {
    useEffect(() => {
       window.scrollTo(0, 0);
   }, [])

   return (
       <div>

<div className="container-fluid">
               <div className="row">
                   <div className="col-lg-12">
                       <Link to="/">
                       <img src={testlogo2} className="img-fluid why-logo" alt="logo"/>
                       </Link>
                       <Link to="/">
                       <button className="why-button">Listings for you</button>
                       </Link>
                   </div>
               </div>
           </div>

<div className="container">
    <div className="row">
        <div className="col-lg-6">
        <img src={careers} className="img-fluid img-career" alt="careers"/>
        </div>

        <div className="col-lg-6">
      <h3 className="careers-h">Careers</h3>
      <h3 className="careers-header">A space to share, a world to gain.</h3>
     <p className="careers-t">We're always looking out for the best, vibrant & bright minds to work & share with.</p>
        </div>


    </div>
</div>

<div className="container">
    <div className="row">
        <div className="col-lg-3">
       
        </div>

        <div className="col-lg-6">
        <h3 className="careers-mx">Our Mission</h3>
        <h3 className="careers-mx-x">Create a world where anyone can belong anywhere.</h3>
        <p className="careers-t-x">It’s an audacious, incredibly rewarding mission that our increasingly diverse team is dedicated to achieving.</p>
        <p className="careers-t-x" >Maineauto parts is built around the idea that everyone should be able to make that perfect purchase, for both new and fairly used autoparts To that end, we empower millions of people around Nigeria and later outside Nigeria to use their spaces, passions, and talents to become entrepreneurs.</p>
        <p className="careers-t-x">Exciting challenges lie ahead—new regions, technologies, and businesses. Guided by our four core values, we’ll meet these challenges creatively and with the support of our global community. Join us!</p>
</div>

        <div className="col-lg-3">

</div>
    </div>
</div>

<div className="container">
    <div className="row">
    <div className="col-md-3">
        </div>

        <div className="col-md-6">
        <h3 className="careers-mx">Work with us</h3>
        <h3 className="careers-mx-x">Open roles & positions.</h3>
        <p className="careers-t-x">Sorry, there is currently no open roles or positions at the moment; Kindly keep tab of this space or check back at later weekly intervals for updates on openings.</p>

        </div>

        <div className="col-md-3">
            
            </div>
    </div>
</div>

<div className="container">
<div className="line"></div>
</div>

<Footer/>

{/* main closing div */}

</div>
    )


    }

export default Careers ;
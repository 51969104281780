
import React, { useRef } from "react";

import close from "../../images/close.svg";
import RequestForm from "./RequestForm";

const RequestModal = (props) => {

  const modalRef = useRef()

  function closeModal(value) {
    // if it's true
    if(value) {
      modalRef.current.click()
    }
  }
  
  return (

<div>

      {/*
        // !rest.match.path.includes('/search')
      // ? */}
      <div className="container-fluid px-0">
      <div className="row">
          <div className="col-lg-12">
      <button className="filter-button request"  data-bs-toggle="modal" data-bs-target="#exampleModal1">Request</button>
          </div>
      </div>
      </div>
      {/*
      // : 
      // ''
      */}


      <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Request For a Motor Part/Equipment</h5>
          <img src={close} alt="close" class="img-fluid" data-bs-dismiss="modal" ref={modalRef} aria-label="Close"/>
          </div>
          <div class="modal-body">
            <RequestForm closeModal={closeModal} disableWrapper={true} {...props} />
            <div className="space-2"></div>
        </div>
       {/* <div class="modal-footer">
          <button type="button" class="btn btn-primary-2">Apply RequestModal</button>
    </div> */}
      </div>
      </div>
      </div>



{/* main closing div */}
</div>


    );

}

export default RequestModal;



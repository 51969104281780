
import {  Box, Flex,
    //  FlexProps,
      Text, Menu,
      Avatar,   MenuButton,  MenuList,MenuItem,IconButton} from '@chakra-ui/react';
   import { AddIcon, ChevronDownIcon, HamburgerIcon} from '@chakra-ui/icons'
   import { Link, useHistory} from 'react-router-dom'

import testlogo2 from "../../images/testlogo2.png";
import profile from "../../images/profile.gif";
import { startSetLogout, startSetUserDetails } from '../../redux/actions/auth';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

const AdminHeader = (props) => {
    // const {isAuthenticated } = props
    const router = useHistory()
    const { startSetUserAction} = props;

    const checkTokenValidity = async () => {
        try {
            const res = await axios.get(`${API_URL}/me`, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
                }
            })
            
            if(res.status === 200) {
                
                console.log(res.data)
                return  startSetUserAction(res.data)
            }else {
                localStorage.removeItem('access_token')
                router.push('/login')
            }
        } catch (error) {
            localStorage.removeItem('access_token')
            router.push('/login')
            // throw error;

            // if(error.response.statu)
        }
    }

     //eslint-disable-next-line  
    useEffect(()=> {
        checkTokenValidity()
        // startSetUserAction(localStorage.getItem('accessToken'));
     //@ts-ignore   
    },
     //eslint-disable-next-line
    []);


    return (
        <Box 
        zIndex='10'
        position='fixed'
    
    
     background="#FFFFFF 0% 0% no-repeat padding-box"
        boxShadow="0px 3px 6px #00000029"
        alignItems='center' d='flex' justifyContent='space-between' px={['10px','10px','10px','10px', '100px']} py='auto' h='100px' w='100%'>
            <Flex>
            <Menu >
            <MenuButton
            d={['unset', 'unset' , 'unset' ,'none' , 'none']}
                mr='10px'
                as={IconButton}
                aria-label="Options"
                icon={<HamburgerIcon />}
                size="sm"
                variant="outline"
            />
            <MenuList>
            <Link to='/dashboard/adverts/create'>
            <MenuItem icon={<AddIcon />}>
                Create An Advert
               </MenuItem>
            </Link>
             
            <Link to='/dashboard/adverts'>
               <MenuItem>
                All Listings
               </MenuItem>
            </Link>


            <Link to='/dashboard/inactive-adverts'>
               <MenuItem>
               Pending Approval Listings
               </MenuItem>
            </Link>

            <Link to='/dashboard/parts-request'>
               <MenuItem>
               Request By MaineAutoParians 
            

               </MenuItem>
            </Link>
            </MenuList>
            </Menu>
           
          <Link to="/">
                  {/* 
              <Img  src={testlogo2} alt="maineautoparts logo" className="" h='40px' w='120px' /> */}  
    <img src={testlogo2} className="logo-admin" alt="logo"/>       
            </Link>
            </Flex>

        <Menu d={['none', 'none', 'none', 'unset']}>
         
            <MenuButton>
            <Flex  d='flex' justifyContent='center'  alignItems='center'>
            <Text mr='10px' className="text-user"> Hi, {props.auth && props.auth.first_name}</Text>
            <Avatar src={props.auth && props.auth.avatar && props.auth.avatar.thumb ?  props.auth.avatar.thumb : profile} h='50px' w='50px' name={props.auth && props.auth.first_name + 
                
                ' ' + props.auth && props.auth.last_name }  />
             {/* <img src={profile} className="img-fluid dealer-pic" alt="profile"/> */}
            <ChevronDownIcon my='auto'/>
            </Flex>
           
            </MenuButton>
            <MenuList>
                <Link to="/">
                <h4 className="dr-menu">HOMEPAGE </h4>
                </Link>
                <Link to="/dashboard/profile">
              <h4 className="dr-menu"> MY ACCOUNT </h4>
               </Link>
                <h4 className="dr-menu" onClick={()=> {
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('user')
                    props.startSetLogoutAction()
                   router.push("/")
                }}>LOG OUT</h4>
              
            </MenuList>
            </Menu>

        </Box>
    )
}

const mapDispatchToProps = (dispatch) => ({
    startSetUserAction: (data) => dispatch(startSetUserDetails(data)),
    startSetLogoutAction: () => dispatch(startSetLogout())
   })
   
   const mapStateToProps = (state) => ({
     auth : state.auth && state.auth.user? state.auth.user : null
   })
   
   
   export default connect(mapStateToProps, mapDispatchToProps)(AdminHeader);
import React from "react"
import { Link } from "react-router-dom"
import { useSelector } from 'react-redux'
import Advert from "./advert"


const Recent = () => {

  const listings = useSelector(state => state.listings)

  return (
    <div>

    <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1 className="featured-listing-header-2">Listings for you. </h1>
                </div>
            </div>
    </div>
    
     
        <div className="container">
         <div className='row'>
         {
          Array.isArray(listings['recent_listings']) && listings['recent_listings'].length > 0
          ?
          listings['recent_listings'].map(ad => (
            <Advert key={ad.id} ad={ad} />
          ))
          :
          ''
        }
         </div>

          <div className="divi">
          <Link to="/categories">
      <button className="all-categories">All categories</button>
      </Link>

             {/* <p><span className="counter-1">1-9</span>of <span className="counter-2">540+ listings</span></p> */}
          </div>
    
       </div>
    
      
    
     {/* main closing div */}
    </div>
  )

}

export default Recent;
import React, { useEffect } from "react";
import Footer from "../partials/footer";
import invite from "../../images/invite.jpg";
import { Link } from "react-router-dom";
import testlogo2 from "../../images/testlogo2.png";
import shareit from "../../images/shareit.svg";

const Invite = () => {
    useEffect(() => {
       window.scrollTo(0, 0);
   }, [])

   return (
       <div>

<div className="container-fluid">
               <div className="row">
                   <div className="col-lg-12">
                       <Link to="/">
                       <img src={testlogo2} className="img-fluid why-logo" alt="logo"/>
                       </Link>
                       <Link to="/">
                       <button className="why-button">Listings for you</button>
                       </Link>
                   </div>
               </div>
           </div>

<div className="container">
    <div className="row">
        <div className="col-lg-12">
        <img src={invite} className="img-fluid invite-image" alt="careers"/>
        </div>

    </div>
</div>


<div className="container">
    <div className="row">
        <div className="col-md-3"></div>

        <div className="col-md-6">
        <div className="col-lg-12">
      <h3 className="careers-i">Invite friends</h3>
      <h3 className="careers-header">There're ways to spread the word.</h3>
     <p className="careers-t">Don't just enjoy the good news and profits that could be made from maineautoparts alone, share the good tidens with friends, family & loved ones.</p>

     
     <a href="https://api.whatsapp.com/send?phone=whatsappphonenumber&text= Buy and sell your favorite auto deals | https:www.maineautoparts.com/how to get started"><img src={shareit} className="img-fluid share-it" alt="share it"/>
<p className="share-with-frd">Share with friends</p>
</a>
   
        </div>
        </div>

        <div className="col-md-3"></div>
    </div>
</div>



   

<div className="container">
<div className="line"></div>
</div>

<Footer/>

{/* main closing div */}

</div>
    )


    }

export default Invite ;
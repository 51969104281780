import { Box , Flex, Text} from '@chakra-ui/react';
import { Link } from 'react-router-dom';



const ListItem = (props) => {
    const { listing , type } = props;
    return (
        <Link to={type? '#': `/dashboard/advert/${listing && listing.id}`}> 
        <Box w='100%' height='100%' backgroun='#FFFFFF'
        boxShadow='0px 3px 6px #00000029'
        borderRadius='3px'
        p='10px'
        d='flex' 
      
        >

            <Box borderRadius='50%' name={listing && listing.title} backgroundSize='cover'  backgroundImage={`url(${listing && listing.images && listing.images[0]})`} height='68px' width='68px'  bgColor='#000000' mr='20px'/>
            {/* //  background="transparent url('img/headlamp.png') 0% 0% no-repeat padding-box"> */}
                <Flex flexDir='column' justifyContent='space-between'>
                 
                   <Text fontSize='15px' color='#00E069'>{listing.title}</Text>
                  {
                   type &&
                    <Text fontSize='16px' color='#000000'>
                    {listing.description}
                    </Text>
                  }
                  
                    <Text fontSize='16px' color='#000000'>{listing && listing.condition && listing.condition.name}</Text>
                    <Text fontSize='16px' color='#707070'>{listing.category && listing.category.name}</Text>
                  
                </Flex>
                
          

        </Box>
        </Link>
    )
}

export default ListItem;
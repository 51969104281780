import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from "react-router-dom"

import Footer from "../partials/footer"
import Navbar from "../partials/navbar"
import NavAds from "../partials/nav_ads"
import ListingContainer from "../partials/listing_container"

import { startFetchListings, startSearchListings } from '../../redux/actions/listing'
import Preloader from "../partials/Preloader"
// import { list } from "@chakra-ui/react"

const Listings = (props) => {

const dispatch = useDispatch()
const listings = useSelector(state => state.listings)
const [pageNotFound, setPageNotFound] = useState(false)
const [search, setSearch] = useState('')
    


    useEffect(() => {

        
        if(!props.match.path.includes('/search')) {
       const slugArr =  props.match.params.slug.split('-')
       const id = +slugArr[slugArr.length - 1] 
      
       if(isNaN(id)){
           setPageNotFound(true)
       }
    //    query
     const qs = { category_id: id}

     const fetchListingBySlugs = (query) => {
        dispatch(startFetchListings(query))
        .then(() => {
            setPageNotFound(false)
    
        }).catch((err) => {
            setPageNotFound(true) 
        });
    }

       fetchListingBySlugs(qs)
    } else {

        // Do a search
        setSearch(props.match.params.search)
       const search = `search=${props.match.params.search}`
       const query = props.location.search + `&${search}`
        dispatch(startSearchListings(query))
        .then(() => {
            setPageNotFound(false)
    
        }).catch((err) => {
            setPageNotFound(true) 
        });
    }
       window.scrollTo(0, 0);
   }, [props, dispatch]) //.match.params.slug, props.match.params.search, props.location.search



//    Redirect to 404 page 
   if(pageNotFound) return (
       <Redirect to='/404' />
   )


   const showCategory = (slug) => {
     if(slug){
       const cat =  slug.split('-')
      return cat.splice(0, cat.length - 1).join(' ').trim()
     }
   }

   return (
       <div>

    <Navbar/>
    <NavAds/>

    <div className="container">
        <div className="row">
            <div className="col-lg-12">
            {
                props.match.path.includes('/search') ?
                   <div>
                    { listings.isLoading ? <Preloader /> : (
            
                        // listings.total > 1 ? 
                        <h6 className="label-tag">
                        Found {listings && listings.total} listings for
                        </h6>
                        //  : <button>Make a request</button>
                    
                    )}
                    <h3 className="label-title">{search} </h3>
                    </div>
                    :
                    <>
                    { listings.isLoading ? <Preloader /> : <h6 className="label-tag">{listings && listings.total} + listings in</h6> }
                    <h3 className="label-title">{showCategory(props.match.params.slug)} </h3>
                    </>
                }
            </div>
        </div>
    </div>
  
<ListingContainer {...props}  isLoading={listings.isLoading} current_page={listings.current_page} data={listings.data} per_page={listings.per_page} last_page={listings.last_page} from={listings.from} to={listings.to} total={listings.total}  />



<Footer/>
{/* main closing div */}

</div>
    )


    }

export default Listings ;
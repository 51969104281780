import axios from 'axios'
import { FETCH_CATEGORIES, LOADING_CATEGORIES } from '../types'

export const fetchCategories = (categories) => ({
    type: FETCH_CATEGORIES ,
    categories
})


export const setLoading = (loadingState) => ({
    type: LOADING_CATEGORIES,
    isLoading: loadingState
})


export const startFetchCategories = () => {

    return async (dispatch) => {

        dispatch(setLoading(true))
        
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/categories`)

             
            if(res.status === 200 && res.data.length > 0) {
    
                // add slug to categories
               
                const serverCategories = Array.isArray(res.data) &&
                                res.data.length > 0  
                                &&
                                res.data.map(cat => {
                                    const slug = cat.name.trim().replaceAll(' ', '-') + `-${cat.id}`
                                    return {
                                        ...cat,
                                        slug
                                    }
                                })
    
                dispatch(fetchCategories(serverCategories))   
                dispatch(setLoading(false))
            } else {
                // dispatch(fetchCategories([]))
                dispatch(setLoading(false))
                
            }
        } catch (error) {
            dispatch(fetchCategories([]))
            dispatch(setLoading(false))
        } 
    }
 
}
import { Box , Accordion, Text, Flex,  AccordionItem, AccordionButton, AccordionPanel, AccordionIcon} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import google from "../../images/google.png";
import apple from "../../images/apple.svg";
const MenuText = (props) => (
    <Link {...props} to={props.to} cursor='pointer' color='#00E069'>
        {props.children}
    </Link>
)

const AdminSidebar = () => {


return  (
    <Box zIndex='50' h='100%' pt='100px'  position='fixed'>
    <Accordion  border='none' border-color='transparent'  color='#000000' _selected={{border: 'none'}}  defaultIndex={[0,1]} allowMultiple>
    <AccordionItem border='none'>
    <h2>
      <AccordionButton _focus={{boxShadow: 'none', background: 'none' , outline: 0}}>
        <Box flex="1"  fontSize='17px' fontWeight='500' fontFamily='Poppins, sans-serif'  border='none' textAlign="left">
          OVERVIEW
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
    <Flex d='flex' flexDir='column'  justifyContent='space-between'>
            {
            [{name: 'All Listing', to: '/dashboard/adverts'} ,
             {name: 'Active Listing', to: '/dashboard/active-adverts', exact: true},
             {name: 'Isnactive Listing', to: '/dashboard/inactive-adverts',  exact: true},
             {name: 'Parts Request', to: '/dashboard/parts-request', exact: true},
            ].map((text, i) => (
              <Box key={i} mt='20px'>
                  <MenuText {...text} to={text.to} py='10px' key={text}>
                    {text.name}
                </MenuText>
              </Box>
              
            ))
            }
    </Flex>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem border='none'>
    <h2>
      <AccordionButton _focus={{boxShadow: 'none', background: 'none' , outline: 0}}>
        <Box flex="1"   fontSize='17px' fontWeight='500' fontFamily='Poppins, sans-serif'  border='none' textAlign="left">
          CREATE
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
    <Flex d='flex' flexDir='column' justifyContent='space-between'>
        
            {
            [{name: 'Add a Listing',url: '/dashboard/adverts/create'}].map((text, i) => (
                <MenuText to={text.url} py='10px' key={i}>
              
                  {text.name}
               
                   
                </MenuText>
            ))
            }
    </Flex>
    </AccordionPanel>
  </AccordionItem>


</Accordion>

<Box px='15px' mt='50px'>
    <Text fontSize='16px' fontWeight='500' marginTop='100px' fontFamily='Poppins, sans-serif' color='#707070'>
       DOWNLOAD
    </Text>

    <img src={google} className="img-fluid logo-goole" alt="logo-google"/>
    <img src={apple} className="img-fluid" alt="logo-apple"/>

    <Text  fontSize='14px' mt='50px' fontFamily='Poppins, sans-serif' fontWeight='500' color='#707070'>
       COPYRIGHT 	&#169; {new Date().getFullYear()}
    </Text>
</Box>
    </Box>
)

          }

export default AdminSidebar
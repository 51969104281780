import React from "react";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
// Import the plugin code
import FilePondPluginImageResize from "filepond-plugin-image-resize";

import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
// Register the plugin
// FilePond.registerPlugin(FilePondPluginImageResize);
// FilePond.registerPlugin(FilePondPluginFileValidateType);

// Register the plugin
// registerPlugin(FilePondPluginImageResize);


// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginImageResize, 
  FilePondPluginFileValidateType , FilePondPluginImageResize,FilePondPluginFileValidateSize );
// FilePond.registerPlugin(FilePondPluginFileValidateSize);
function AdvertUpload(props) {

  return (
    <div className="App">
      <FilePond
        acceptedFileTypes={['image/*']}
        allowImageResize={true}
        imageResizeTargetWidth={'820'}
        imageResizeTargetHeight={'512'}
        allowMultiple={true}
        maxFiles={3}
        maxFileSize={'10MB'}
        maxTotalFileSize={'50MB'}
        imagePreviewHeight="100"
        imagePreviewWidth="150"
        labelIdle={props.title ?? 'Drag & Drop your files or <span class="filepond--label-action">Browse</span>'}
        files={props.files}
        allowReorder={true}
        // imageResizeUpscale={false}
        onupdatefiles={props.setFiles}
        // labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
    </div>
  );
}

export default AdvertUpload;




import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { useDispatch } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import { startSignUp } from "../../redux/actions/auth"

const Form_sign_up = () => {

const dispatch = useDispatch()
const history = useHistory()
const [alertMessage, setAlertMessage] = useState({ type: 'error', message: ''})

const validationSchema = yup.object({
    name: yup.string().trim().required('Name is required'),
    email: yup.string().trim().email('Invalid email address').required('Email address is required'),
    phone: yup.string().trim().required('Phone number is required'),
    password: yup.string().trim().required('Password is required').min(6, 'Your password must be at least 6 characters long'),
    password_confirmation: yup.string().trim().oneOf([yup.ref('password'), null], 'Password does not match')
});


 return (
 <div>
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="card-sign-up">
                    <h3 className="form-title">Sign up now. it's free</h3>
                    {
                        alertMessage && alertMessage.type === 'error'
                        ?
                        alertMessage && Object.values(alertMessage.message).map((error, index) => (
                            <p key={index} style={{ color: 'red', textAlign:'center'}} >{error[0]}</p>
                            )
                        )
                        :
                        <p style={{ color: 'green', textAlign:'center'}} >{alertMessage.message}</p>
                    }
                    <Formik
                    initialValues={{ name: '', email: '', phone: '', password: '', password_confirmation: '' }}
                    validationSchema={validationSchema}
                    onSubmit = {
                      async  (values, actions) => {

                            setAlertMessage({ type: 'error', message: ''})

                            let fullname = values.name.split(' '), firstname, lastname;

                            if (fullname.length === 1) {
                                firstname = lastname = fullname[0];
                            } else {
                                [firstname, lastname] = fullname;
                            }

                            try {
                            const result =  await dispatch(startSignUp({
                                    first_name: firstname,
                                    last_name: lastname,
                                    email: values.email,
                                    phone: values.phone,
                                    password: values.password,
                                })
                                )
                              
                                if(result) {
                                    // route to the homepage
                                    history.push('/account_success')
                                    actions.setValues({
                                        name: '',
                                        phone: '',
                                        email: '',
                                        password: '',
                                        password_confirmation: ''
                                    })


                                    setAlertMessage({ type: 'success', message: 'Account Registration successful'})
                                }

                            } catch (error) {
                                // dipslay errors on forms
                                // unprocessed entity
                                switch (error.response.status) {
                                    case 422:
                                        setAlertMessage({type:'error', message: error.response.data.errors})
                                        break;
                                        
                                        default:
                                        // setAlertMessage(error.response.data.errors)
                                        break;
                                        
                                }
                               
                            }
                        }
                    }
                    >
                    {
                        props => (
                            <Form onSubmit={props.handleSubmit}>
                                <Field  type='text' name='name'  className="overide input-text" placeholder="My names are e.g paul adams"/>
                                <ErrorMessage name='name' component='div' className="error-handlers"/>
                                <Field type='text' name='phone' className="overide input-number" placeholder="Mobile number"/>
                                <ErrorMessage name='phone' component='div' className="error-handlers" />
                                <Field type='email' name='email' className="overide input-email" placeholder="Email address e.g john@gmail.com"/>
                                <ErrorMessage name='email' component='div' className="error-handlers" />
                                <Field type='password' name='password' className="overide input-password" placeholder="Choose password"/>
                                <ErrorMessage name='password' component='div' className="error-handlers" />
                                <Field type='password' name='password_confirmation' className="overide input-password" placeholder="Re-type password"/>
                                <ErrorMessage name='password_confirmation' component='div' className="error-handlers" />
                                <button type='submit' className="sign-up-btn" disabled={props.isSubmitting}>{props.isSubmitting ? 'Signing up...' : 'Create my account'}</button>   
                            </Form>
                        )
                    }   
                    </Formik>
                </div>
                <p className="terms-form">By signing up, I have read & agreed to Maine autopart(s) <span className="tt"><Link to="/privacy"className="aa-colo-2">Read terms</Link></span></p>
            </div>
        </div>
    </div>


{/* main closing div */}
</div>
)

};

export default Form_sign_up ;

import React from "react"
import { 
  // BrowserRouter as Router,
   Route, Switch } from "react-router-dom"
import "../App.css"
import "../mobile.css"
import Getstarted from "../components/pages/getstarted"     
import Listing from "../components/pages/listing"
import Signup from "../components/pages/signup"
import Login from "../components/pages/login"
import Privacy from "../components/pages/privacy"
import Help from "../components/pages/help"
import Listings from "../components/pages/ads_mechanical"
// import Ads_electrical from "../components/pages/ads_electrical"

import Listing_details from "../components/pages/listing_details"
// import Fogot_password from "../components/pages/fogot_password";
import Forgot_password from "../components/pages/fogot_password"
import Password_reset from "../components/pages/password_reset";

import Not_found from "../components/pages/not_found";
import Categories from "../components/pages/categories";
import Account_success from "../components/pages/account_success";
import How from "../components/pages/how";
import Why from "../components/pages/why";
import Invite from "../components/pages/invite";
import Careers from "../components/pages/careers";
import Contact from "../components/pages/contact"
function ClientRoutes(props) {
//   const router = useHistory(); 
  return (
    <React.Fragment>
    {/* <ChakraProvider> */}
        {/* <AdminHeader/> */}
           
              <Switch>
               <Route exact path="/" component={Listing} />
                <Route path="/getstarted" component={Getstarted} />
                <Route path="/categories" component={Categories} />
                <Route path="/signup" component={Signup} />
                <Route path="/Login" component={Login} />
                <Route path="/Privacy" component={Privacy} />
                <Route path="/Help" component={Help} />
                {/* <Route path="/Ads_electrical" component={Ads_electrical} /> */}
               
                <Route path="/Forgot_password" component={Forgot_password} />
                <Route path="/Password_reset" component={Password_reset} />
                
         
                <Route path="/Account_success" component={Account_success}/>
                <Route path="/How" component={How}/>
                <Route path="/Why" component={Why}/>
                <Route path="/contact" component={Contact}/>
                <Route path="/Careers" component={Careers}/>
                <Route path="/Invite" component={Invite}/>
                <Route  path='/404' component={Not_found}/>
                <Route path="/:slug" exact component={Listings} />
                <Route path="/search/:search" component={Listings} />
                <Route path="/ads/:ad" component={Listing_details} />
              
              </Switch>
            
       
        {/* </ChakraProvider> */}
        </React.Fragment>
     
  );
}


export default ClientRoutes;

    import { Box ,Flex , SkeletonText, SkeletonCircle, Button, SimpleGrid , BeatLoader, Grid} from '@chakra-ui/react';
    import {ArrowForwardIcon , ArrowBackIcon} from '@chakra-ui/icons'
import ListItem from './ListItem';
import { useEffect, useState } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL
    const AdminIndex = () => {
        const [listings, setListings] = useState({})
        const [loading, setLoading] = useState(true)

const fetchListings = async () => {
    try {

        const res = await axios.get(`${API_URL}/adverts`, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization' : `Bearer ${localStorage.access_token}`
            }
        })
        setLoading(false)
        if(res.status === 200) {
            console.log(res.data)
            await setListings(res.data)
            return res.data.message
        }
        // setLoading(false)
    } catch (error) {
        setLoading(false)
    }
}

const fetchNextListings = async () => {
    setLoading(true)
    try {

        const res = await axios.get( listings.next_page_url, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization' : `Bearer ${localStorage.access_token}`
            }
        })
        setLoading(false)
        if(res.status === 200) {
            console.log(res.data)
            await setListings(res.data)
            return res.data.message
        }
        // setLoading(false)
    } catch (error) {
        setLoading(false)
    }
}

const fetchPrevListings = async () => {
    setLoading(true)
    try {

        const res = await axios.get( listings.prev_page_url, {
            headers: {
                // 'X-Requested-With': 'XMLHttpRequest',
                'Authorization' : `Bearer ${localStorage.access_token}`
            }
        })
        setLoading(false)
        if(res.status === 200) {
            console.log(res.data)
            await setListings(res.data)
            return res.data.message
        }
        // setLoading(false)
    } catch (error) {
        setLoading(false)
    }
}

useEffect(()=> {
    fetchListings()
},[])
 
        return  (
           
     <Box m='auto' p={['20px','10px']}>
          <h3 className="dash-head-title">All your previous & recent listings</h3>
      { loading ? (
          <SimpleGrid  zIndex='400' m='auto' minChildWidth="280px" spacing="100px">
          {[0,1,2,3,4,5,6,7,8].map((data) => (
            <Box  height="120px" key={data} d='flex' padding="6" boxShadow="lg" bg="white">
            <SkeletonCircle size="10" mr='10px'/>
            <SkeletonText  noOfLines={3} spacing="3" />
            </Box>   ))}
        </SimpleGrid>)
      
      
            :

        <> 
          <Grid  zIndex='400' m='auto' templateColumns={["repeat(1, 1fr)" , 
          "repeat(2, 1fr)", "repeat(2, 1fr)",
          "repeat(2, 1fr)",  "repeat(3, 1fr)"]} gap='6' minChildWidth="280px" 
          spacing={["10px" , "10px", "20px" ,"40px"]}
          >
           { listings && listings.data && listings.data.map((data, index)=> (
                    <Box   key={index} height="120px">
                        <ListItem listing={data}/>
                    </Box>
                    )
               )}
               

            
            </Grid>

            <Flex w='100%' justifyContent='space-between' fontWeight='500' fontFamily='Poppins, sans-serif' mt='50px' mb='100px'>
                   <Box>{listings.from} - {listings.to} of {listings.total} <span className="diif">listings</span></Box>
                   <Box>
                    <Flex>
                {
                    !(listings.from === 1) && (
                    
                        <Button
                        isLoading={loading}
                        onClick={fetchPrevListings}
                        // colorScheme="blue"
                        mr='20px'
                        spinner={<BeatLoader size={8} color="white" />}
                        color='#00E069' rightIcon={<ArrowBackIcon />} colorScheme="teal" variant="outline">
                           PREVIOUS
                    </Button>
                    )
                }
                    
                {
                !(listings.to === listings.total) && (

                <Button
                        isLoading={loading}
                        onClick={fetchNextListings}
                        // colorScheme="blue"
                        spinner={<BeatLoader size={8} color="white" />}
                        color='#00E069' rightIcon={<ArrowForwardIcon />} colorScheme="teal" variant="outline">
                            NEXT
                    </Button>
                )
                }

                    
                    </Flex>   
                  
                   </Box>
               </Flex>
               </>

        }  
           

         </Box>
        )
    }


    export default AdminIndex;
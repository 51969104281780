import { theme as chakraTheme , extendTheme } from '@chakra-ui/react'

export const grayGradient = "linear-gradient(147deg, rgba(141, 141, 236, 0.17) 0%, rgba(84, 84, 212, 0) 100%)";

const  theme = {
    ...chakraTheme,
    styles: {
        
        global: {
          // styles for the `body`
          html: {
            display: 'inline-table',
            width: '100%'
          },

          body: {
            // background: '#E5E5E5 !important'
            // overflowX: 'hidden'
          },
          '.algolia-box' :{
            width: '100%'
          },
          '.algolia-box.div': {
            width: '100%'
          },
          '.apexcharts-tooltip': {
            opacity: '1  !important',
          },
      
        '.pgress-green div': {
            backgroundColor: '#1DC39A'
        },

        '.chakra-switch__track': {
            backgroundColor: '#F5F6F8 !important',
            outline: 'none !important',
            boxShadow: 'none !important',
        },
        '.chakra-switch__track:focus': {
            outline: 'none !important',
            boxShadow: 'none !important',
        },
        '.chakra-switch__track .chakra-switch__thumb': {
            backgroundColor: '#CDD2FD'
        },
        '.chakra-switch__track[data-checked] .chakra-switch__thumb': {
            backgroundColor: '#6979F8'
        },
        
        },
       
    },

    colors: {
        brandBlack: '#263238',
        grayy: {
            50: "#f7fafc",
            // ...
            900: "#1a202c",
          },
        brand: {
            50: '#F8F8FF',
            200: '#F8F8FF',
            500: '#F8F8FF',
            900: "#6979F8",
            1000: '#343D63'
        },
        afroYellow: '#EACE76',
        brandSecondary: {
            900: "#1DC39A"
        },
        footer: '#19191B',

    },
    fonts: {
        body: "'SFProDisplay', system-ui,  sans-serif",
        heading: "'SFProDisplay', system-ui,  sans-serif",
        mono: "'SFProDisplay', system-ui,  sans-serif",
    },

    components: {

        Progress: {
        
                color:'#343D63',
                backgroundColor: '#343D63',
                variants: {
                    primaryAfro : {
                    }
                }
            },
        Switch: {
            defaultProps: {
                // size: string;
                colorScheme: 'brand.500',
            }
        },
        Button: {
            fontSize: '15.67px',
            variants: {
                primaryAfro : {
                fontSize: '15.67px',
                fontWeight: 500,
                backgroundColor: '#6979F8',
                color: 'white',
                borderRadius: '4.42363px',
            },

            secondaryAfro : {
                fontSize: '15.67px',
                fontWeight: 500,
                bg: '#1DC39A',
                color: 'white',
                borderRadius: '4.42363px',
                border: '2px solid #1DC39A'
            },

            secondaryBlack : {
                fontSize: '15.67px',
                fontWeight: 500,
                bg: '#263238',
                color: '#FFFFFF',
                borderRadius: '4.42363px',
                border: '2px solid #263238'
            },

        } 
        }
    }
}


export default extendTheme(theme);

import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

// Reducers
import authReducer from './reducers/authReducer'
import categoryReducer from './reducers/categoryReducer'
import listingReducer from './reducers/listingReducer'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const configureStore = () => {
    const store  = createStore(
        combineReducers({
            'auth': authReducer,
            'categories': categoryReducer,
            'listings': listingReducer,
        }),
        composeEnhancers(applyMiddleware(thunk))
    )

    return store
} 

export { configureStore as default }
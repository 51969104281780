import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
 
class GoogleSuggest extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: '' };
  }
 
  handleChange = address => {
    this.setState({ address });
    this.props.clear();
  };
 
  handleSelect = async address => {
    this.setState({address})
    const longlang = await geocodeByAddress(address)
    .then(results => getLatLng(results[0]))
    .then(latLng =>  latLng)
  //   .then 
    .catch(error => console.error('Error', error));
      (geocodeByAddress(address).then(async (r) => {
        // console.log(r)
        const country = await r[0].address_components.filter(data => data.types.includes('country'))[0];
        const lga =  await r[0].address_components.filter(data => data.types.includes('administrative_area_level_2'))[0];
        const city =  await r[0].address_components.filter(data => data.types.includes('neighborhood'))[0] //administrative_area_level_1
        const state =  await r[0].address_components.filter(data => data.types.includes('administrative_area_level_1'))[0]
        console.log(state)
        const data = {
          
            address: address,
            city: city && city.long_name,
            lga: lga && lga.long_name,
            state: state && state.long_name,
            country: country && country.long_name,
            longitude: longlang.lng,
            latitude:  longlang.lat,
            
          };
          console.log(data)
          this.props.onSelect(data)
          
      }))
 
  };
 
  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        className='input-text' 
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                // className: 'location-search-input',
                className: 'input-text-google' 
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default GoogleSuggest;
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Flex,Text, Input, Select, Textarea, FormControl, FormErrorMessage, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux'
import axios from 'axios';
import { useFormik } from "formik";
import * as yup from 'yup';
import GoogleSuggest from "../GoogleSuggest";
import AdvertUpload from "./AdvertUpload";
import { startFetchAd } from "../../redux/actions/listing";
import { Redirect } from "react-router";
import doodle from "../../images/doodle.svg";

const { REACT_APP_API_URL } = process.env;


const CusInput = (props) => {
    if (props.variant === 'text-area') {
        return (
        <Textarea w='100%' {...props} borderColor='#939393'  border="1px solid #939393 !important" borderRadius="5px !important">

        </Textarea>
        )
    }else {
        return(
            <Input {...props} borderColor='#939393' 
            w='100%' border="1px solid #939393 !important" 
            borderRadius="5px !important">
    
            </Input>
        )
       
    }
   
}

let validationSchema1 = yup.object({
    title: yup
      .string()
      .required(),
    description: yup
      .string()
      .required(),
      category_id: yup
      .string()
      .required(),
      condition_id: yup
      .string()
      .required(),
      brand_id: yup
      .string()
      .required(),
      brandmodel_id: yup
      .string(),
    //   .required(),
      price: yup
      .string()
      .required(),
    address: yup
      .object({
        address: yup.string().required()})
 })


const CreateAdvertPage = (props) => {



    const toast = useToast()
    const dispatch = useDispatch()
    const listings = useSelector(state => state.listings)
    const {ad} = listings
    const [advert, setAdvert] = useState(undefined)
    const [notFound, setNotFound] = useState(false)
    const [files, setFiles] = useState([]);
    let [ step, setStep] = useState(0);
    const [categories, setCategories] = useState([])
    const [conditions, setConditions] = useState([])
    const [brands, setBrands] = useState([])
    const [brandmodels, setBrandmodels] = useState([])
    // const [filt_brandmodels, setFIltBrandmodels] = useState([])

    // isEditpath
    const isEditRoute = props && props.location.pathname.includes('/advert/edit/')


   validationSchema1 = isEditRoute ? yup.object({
    title: yup
    .string()
    .required(),
  description: yup
    .string()
    .required(),
    category_id: yup
    .string()
    .required(),
    condition_id: yup
    .string()
    .required(),
    brand_id: yup
    .string()
    .required(),
    brandmodel_id: yup
    .string()
    .required(),
    price: yup
    .string()
    .required(),
//   address: yup
//     .object({
//       address: yup.string().required()})
   })  : validationSchema1
    
    const fetchCategories = async () => {
       const { data } = await axios.get(REACT_APP_API_URL+ '/categories');
       setCategories(data);
     
    }

    const fetchConditions = async () => {
        const { data } = await axios.get(REACT_APP_API_URL+ '/conditions');
        setConditions(data.data);
       
     }

     const fetchBrands = async () => {
        const { data } = await axios.get(REACT_APP_API_URL+ '/brands');
        setBrands(data.data);
       
     }


    //  Fetch the Add 
    const fetchAd = (props) => {
        const {match} = props
        if(isNaN(match.params.id) ){
            setNotFound(true)
            return;
        }


        // set uploaded file either from server or cliennt
        function setUploadedFiles(ad) {
            let  uploadedFiles
            if(ad && Array.isArray(ad.images)) {
              uploadedFiles =  ad.images.map(url => {
                return (
                    {
                        source: url,
                        options: { type: 'local'}
                    }
                )
            })
           
            setFiles(uploadedFiles)   
          }
        }



        if(!Object.keys(ad).length > 0 || typeof match.params.id === 'number') {
            dispatch(startFetchAd(+match.params.id))
                    .then((result) => {
                        const {ad} = result
                        setAdvert(ad)
                        setUploadedFiles(ad)
                       
                    })
                    .catch(error => {
                        console.log(error)
                        setNotFound(true)
                    })
        }

        setAdvert(ad)
        setUploadedFiles(ad)
    }


    // Only call this if route matches /advert/edit
    useEffect(() => {
      if(isEditRoute) {
        fetchAd(props)
      }
      }, [props])

    //  const fetchBrandModels = async () => {
    //     const { data } = await axios.get(REACT_APP_API_URL+ '/brandmodels');
    //     setBrandmodels(data.data);
       
    //  }

    useEffect(()=> {
        fetchCategories()
        fetchConditions()
        fetchBrands()
        // fetchBrandModels()
    },[])

    useEffect(()=> {
        console.log(files)
    },[files])

//    const [ onUploadProgress, setUploadProgress]= useState("");
    const [isUploading, setIsUploading] = useState(false);

    // console.log(advert)
   
    const formik = useFormik({
        enableReinitialize: true,
        validateOnChange: true,
        // validateOnMount: true,
        initialValues: {
          title:  advert && advert.title ? advert.title : '',
          description: advert && advert.description ? advert.description : '',
          category_id: advert && advert.category_id ? advert.category_id : '',
          condition_id: advert && advert.condition_id ? advert.condition_id : '',
          brand_id: advert && advert.brand_id ? advert.brand_id : '',
          brandmodel_id: '',
          price: advert && advert.price ? advert.price : '',
          year: '',
          expiry_date: '',
          address: {}
        },
        validationSchema: validationSchema1,
        onSubmit: async values => {
            // return alert(JSON.stringify(values))
            setIsUploading(true);
            const formData = new FormData()

            if(!isEditRoute){
                // @ts-ignore
             const file_d =  files.map(file =>  {
                return  formData.append('images[]', file.file);
                })
             formData.append('images', file_d)
             formData.append('title', values.title)
             formData.append('description', values.description)
             formData.append('category_id', values.category_id)
             formData.append('condition_id', values.condition_id)
             formData.append('expiry_date', values.expiry_date)
             formData.append('year', values.year)
             formData.append('brand_id', values.brand_id)
       formData.append('brandmodel_id', values.brandmodel_id)
             formData.append('price', values.price)
             formData.append('address', values.address.address)
             formData.append('city', values.address.city)
             formData.append('lga', values.address.lga)
             formData.append('surburb', values.address.surburb)
             formData.append('state', values.address.state)
             formData.append('postal_code', values.address.postal_code)
             formData.append('longitude', values.address.longitude)
             formData.append('latitude', values.address.latitude)
            

             try {
                const response = await axios.post(`${REACT_APP_API_URL}/create-advert`, formData ,
                    {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                            'Content-Type': 'multipart/formdata'
                        },
                        onUploadProgress: progressEvent => {
                                //  let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                                    // setUploadProgress(percentCompleted);
                                    // do whatever you like with the percentage complete
                                    // maybe dispatch an action that will update a progress bar or something
                        }
                    }
                    )
    
                    if(response.status === 201 ) {
                        // const { _id } = response.data.data
                       
                        setIsUploading(false)
                        toast({
                            position: 'top-right',
                            title: "Advert upload complete",
                            description: "Will be reviewed by admin",
                              status: "success",
                            duration: 15000,
                            isClosable: true,
                        });
    
                    }
                    setIsUploading(false)
                    formik.resetForm()
                    setFiles([])
                    setStep(0)
    
                } catch (error) {
                    console.log(error)
                    setIsUploading(false)
                    toast({
                        position: 'top-right',
                        title: "Adverts upload failed",
                        description: "Please retry",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                    setIsUploading(false)
                }
            }

             else {
                //  update advert details 
                // const formData =  new FormData()

                // console.log('i dey here', values)
                

                // for (let key in values) {
                //     formData.append(key, values[key])
                // }


                try {
                    const {match} = props
                    const response = await axios.put(`${REACT_APP_API_URL}/adverts/${+match.params.id}`, {...values, price: values.price.toString()} ,
                    {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                            'Content-Type': 'application/json'
                        },
                    }
                    )

                    if(response.status === 201 || response.status === 200 ) {
                        // const { _id } = response.data.data
                       
                        setIsUploading(false)
                        toast({
                            position: 'top-right',
                            title: "Advert Updated Successfully",
                            // description: "Will be reviewed by admin",
                              status: "success",
                            duration: 5000,
                            isClosable: true,
                        });
    
                    }
                    setIsUploading(false)
                    formik.resetForm()
                    setFiles([])
                    if(response.data) {
                        for (const key in response.data.data) {
                            formik.setFieldValue(key, response.data.data[key])
                        }
                    }
                    setStep(0)
                    
                } catch (error) {
                    setIsUploading(false)
                    toast({
                        position: 'top-right',
                        title: "Adverts update failed",
                        description: "Please retry",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                    setIsUploading(false)
                }
             }
           
         
           
        },
      });

      const stepHandler = async  ()=> {
        switch (step) {
            case 0:
                await formik.validateField('description');
                await formik.validateField('title');
              
                        if(formik.errors.title || formik.errors.description) {
                          return;
                        } else {
                            setStep(step + 1);
                        }
              
                break;

            case 1 :
                await formik.validateField('category_id');
                await formik.validateField('brand_id');
                await formik.validateField('brandmodel_id');
                await formik.validateField('condition_id');
                await formik.validateField('price');
                // setStep(step + 1);
                        if(formik.errors.category_id || formik.errors.brand_id
                            // || formik.errors.brandmodel_id 
                            || formik.errors.condition_id
                            || formik.errors.price
                            ) {
                          return;
                        } else {
                            setStep(step + 1);
                        }
                break;

            // case 2 || '2':
            // await formik.validateField('title');
            // await formik.validateField('description');
            // if(formik.isValid) {
            //     alert('submit')
            //     // setStep(step + 1);
            // }
            // break;
        
            default:
                break;
        }
    };

      useEffect(()=> {
        if (!formik.values.brand_id) {
            setBrandmodels([]);
        }else {
          
             if(brands.length === 0) {
                 fetchBrands()
             } 
            //  const data =  brands.filter(data => data.id === formik.values.brand_id );
            //  console.log(data[0].brand_models);
              const  data =  brands.find(b => b.id === +formik.values.brand_id );
              console.log(brands, data, formik.values.brand_id);
             !isEditRoute ?  setBrandmodels(data.brand_models) : data && data.brand_models && setBrandmodels(data.brand_models)
        }
    },[formik.values.brand_id, brands])



    // if edit route 
     // not an id
     if(isEditRoute && notFound){
        return  <Redirect to='/404' />
      }

    return (
        <Box px='20px'>
            

            <Flex alignItems='center' position='relative' flexDir='column' p='20px' width={['100%px' ,'100%px','400px',"692px"]} minHeight="526px" boxShadow="0px 2px 8px #00000029" borderRadius="15px">

              <Flex justifyContent='space-between' w='100%'>
                  <Text>Step {step + 1}</Text>
                  {
                    step > 0 && (
                        <Button onClick={()=> {
                            if(step < 1){
                              // return alert('ok')
                            }else {
                              setStep(step - 1)
                            }
                          
                        } }>Previous</Button>
                    )
                  }
                 
              </Flex>

            {

                step === 0 && (
                    <>
               {
                !isEditRoute &&
                 <Box mx='auto'
                    w='100%' maxWidth='600px' 
                    minH='102px' 
                    bg='#FFFFFF' borderRadius='5px' border="1px dashed #939393">
                        {<AdvertUpload files={files} setFiles={setFiles}/>}
                        
                </Box>
                }

               
                    <CusInput  
                        isInvalid={formik.errors.title}
                        id='title'
                        name='title'
                        onChange={v => formik.setFieldValue('title', v.target.value)}
                        // onBlur={formik.handleChange}
                        value={formik.values.title} 
                        mx='auto' placeholder='Item Title e.g Toyota camry headlamp' mt='20px' />
                    <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
                
                

                <FormControl mx='auto' w='100%' isInvalid ={formik.touched.title && formik.errors.title}>
                    <Text color='black'>
                       { formik.errors.title}
                    </Text>
                <CusInput 
                    isInvalid={formik.errors.description}
                    name='description'
                    // onChange={formik.handleChange}
                    onChange={v => formik.setFieldValue('description', v.target.value)}
                    value={formik.values.description}
                    variant='text-area' mx='auto'
                    placeholder='Describe the item you are trying to sell' mt='20px' />
                </FormControl>
                    </>
                )
            }

            {
                step === 1 && (
                    <>
                    <Select 
                    
                    value={formik.values.category_id} name='category_id' onChange={formik.handleChange} mt='20px' placeholder="Select Item Category" w='100%' borderColor="#939393" border="1px solid #939393 !important" borderRadius="5px !important">
                    <option value="">Select Category</option>
                        { categories.map((data)=> (
                           
                              <option  key={data.id} selected={advert && advert.category_id === data.id} value={data.id}>{data.name}</option>
                        ))  }
                      
                    </Select>
                
               
                    <Select value={formik.values.brand_id}  name='brand_id' onChange={formik.handleChange}  mt='20px' w='100%' border="1px solid #939393 !important" borderColor="#939393" borderRadius="5px !important">
                    <option value="">Select Brand</option>
                    { brands.map((data)=> (
                              <option key={data.id} selected={advert && advert.brand_id === data.id} value={data.id}>{data.name}</option>
                        ))  }
                    </Select>

                    {
                        brandmodels.length > 0 &&
                        <Select value={formik.values.brandmodel_id}  name='brandmodel_id' onChange={formik.handleChange} mt='20px'  w='100%' borderColor="#939393" border="1px solid #939393 !important" borderRadius="5px !important">
                        <option value="">Select Brand Model</option>
                        { brandmodels && brandmodels.map((data)=> (
                                  <option key={data.id} selected={advert && advert.brandmodel_id === data.id}  value={data.id}>{data.name}</option>
                            ))  }
                        </Select>
    
                    }
                   
                    <Select value={formik.values.condition_id} name='condition_id' onChange={formik.handleChange} mt='20px'  w='100%' borderColor="#939393" border="1px solid #939393 !important" borderRadius="5px !important">
                    <option value="">Select Condition</option>
                    { conditions.map((data)=> (
                              <option key={data.id} selected={advert && advert.condition_id === data.id}  value={data.id}>{data.name}</option>
                        ))  }
                    </Select>
                    <FormControl mx='auto' w='100%'>
                    <CusInput value={formik.values.price} id='price' name='price' 
                     onChange={formik.handleChange}
                    type='number' mx='auto' placeholder='Price' mt='20px' borderColor="#939393"/>
                    </FormControl>
                    {/* 
                    <CusInput variant='text-area' mx='auto'
                    placeholder='Describe the item you are trying to sell' mt='20px' /> */}
                    </>
                )
            }

        {
                step === 2 && (
                    <Box className='algolia-box' mt='30px'>
                  {/* {JSON.stringify(formik.values.address)} */}
                    {/* <AlgoliaSearch onClear={() => { formik.setFieldValue('address', '')}} onSelect={(val => formik.setFieldValue('address', val))}/> */}
                   { !isEditRoute ? 
                   <GoogleSuggest clear={()=> formik.setFieldValue('address', {})} onSelect={(val => formik.setFieldValue('address', val))} />
                
                   :
                   <div>
                  <img src={doodle} className="img-fluid doodle-update" alt="doodle"/>
                   <h3 className="update-doodle">Proceed with listing update</h3>
                   </div>
                }

                    </Box>

                    
                )
            }
               

              {
                  step < 2 ? (

                    <Button mt='40px'
                     mx='auto' bottom='20px'
                        onClick={()=> stepHandler()} color='#ffffff' minW='220px'
                        background="#00E069 0% 0% no-repeat padding-box" 
                        borderRadius="5px !important">
                        Next
                    </Button>
                  ) :
                  (
                <Button 
                position='absolute'
                     mx='auto' bottom='50px'
                    isLoading={ isUploading }
                    loadingText= { !isEditRoute ? 'Creating Advert' : 'Updating Advert'}  
                    onClick={()=> formik.submitForm()} color='#ffffff' minW='220px'
                    background="#00E069 0% 0% no-repeat padding-box" 
                    borderRadius="5px !important">
                    {isUploading ?  'uploading advert'   :  isEditRoute ? 'Update' : 'Create Advert'}
                </Button>
                  )
              }
                     


              
       </Flex>


        </Box>
    )
}

export default CreateAdvertPage;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { useDispatch } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import { startPasswordReset } from "../../redux/actions/auth"

import useQuery from "../../hooks/useQuery"

  

const New = () => {


    const dispatch = useDispatch()
    const query = useQuery()
    const [alertMessage, setAlertMessage] = useState({ type: 'error', message: '' })
    const [token, setToken] = useState('')

    const validationSchema = yup.object({
        email: yup.string().trim().email('Invalid email address').required('Email address is required'),
        password: yup.string().trim().required('Password is required').min(6, 'Your password must be at least 6 characters long'),
        password_confirmation: yup.string().trim().oneOf([yup.ref('password'), null], 'Password does not match')    
    });

    useEffect(() => {
        // qs built with the email link
       if(query.has('token')){
           setToken(query.get('token'))
       }
    }, [])

 return (
 <div>
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="card-reset">
                    <h3 className="form-title">Reset password</h3>
                    { !!alertMessage.message
                        && 
                        <p style={{ color: alertMessage.type === 'error' ? 'red' : 'green', textAlign: 'center',fontSize:'13px', fontFamily:'Circular Std'}} >{alertMessage.message}</p>}
                        <Formik
                     
                        initialValues={{ email: '', password: '', password_confirmation: '' }}
                        validationSchema={validationSchema}
                        onSubmit={
                           async (values, actions) => {
    
                                setAlertMessage({ type: 'error', message: '' })
                                try {
                                    dispatch(startPasswordReset({...values, token }))
                                    .then((result) => {
                                        if(result){
                                            values.password = ''
                                            setAlertMessage({ type: 'success', message: 'Your password reset is successful, login with new password.'})
                                        }
                                    })
                                    .catch((error) => {
                                    
                                        switch (error.response.status) {
                                            case 400:
                                                setAlertMessage({type: 'error', message: `${error.response.data.email}`})
                                                break;
                                            case 500:
                                                setAlertMessage({type: 'error', message: 'There\'s a problem reseting your password at this time, please try again later'})
                                                break;
                                        
                                            default:
                                                setAlertMessage({type: 'error', message: 'Password reset failed'})
                                                break;
                                        }
                                    })
    
                                } catch (error) {
                                    setAlertMessage({type: 'error', message:'Password reset failed'})   
                                }
                            }
                        }
                        >
                         {
                             props => (   
                    
                            <Form onSubmit={props.handleSubmit}>

                                <Field type='text' name='email' className="overide input-password" placeholder="Enter your address"/>
                                <ErrorMessage name='email' component='div' className="error-handlers" />

                                <Field type='password' name='password' className="overide input-password" placeholder="Enter new password"/>
                                <ErrorMessage name='password' component='div' className="error-handlers" />
                           
                                <Field type='password' name='password_confirmation' className="overide input-password" placeholder="Confirm password"/>
                                <ErrorMessage name='password' component='div' className="error-handlers" />
                        
                                <button type='submit' className="sign-up-btn">Update passsword</button>   
                                <p className="reset">Your new password must be at least 6 characters long and contain at least 6 unique characters</p>
                            </Form>
                            )
                        }
                        </Formik>
                           
                
                </div>

            </div>
        </div>
    </div>


{/* main closing div */}
</div>
)

};

export default New ;

import React from "react"
import { useDispatch } from 'react-redux'
import Advert from './advert'
import Filters from "../partials/filters";
// import close from "../../images/close.svg"
import next from "../../images/next.svg"
import { startFetchListings } from "../../redux/actions/listing"
import Preloader from "./Preloader";
import RequestModal from "./RequestModal";


const Listing_container = ({ isLoading, current_page, last_page, data, per_page, from, to, total, ...rest }) => {

  const dispatch = useDispatch()

  const fetchNextPage = () => {
      const nextPage = current_page + 1;
      
      // Query Param
      const pageQuery = { page: nextPage }

     dispatch(startFetchListings(pageQuery))
  }

  const fetchPrevPage = () => {
    const prevPage = current_page - 1;
    
    // Query Param
    const pageQuery = { page: prevPage }

   dispatch(startFetchListings(pageQuery))
}



  return(
<div>

<Filters {...rest}/>


<div className="container">
  <div class="row">
      {
        isLoading ?
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh'}}>
        <Preloader />
        </div>
         :
        Array.isArray(data) && data.length > 0 
        ?
        data.map(ad => (
          <Advert key={ad.id} ad={ad} />
        )
        )

        :

        rest.match.params && rest.match.params.hasOwnProperty('search') ? (
           <h3> No result found for your search please make a request  <RequestModal /> </h3> ) : <h3> No Advert in this category yet </h3>
      }
  </div>


   <div className="divi">
    { total > per_page ? <h3  style={{display: current_page !== last_page ? 'block' : 'none'}}  className="next-text" onClick={  () => fetchNextPage()}>Next <img src={next} className="img-fluid next" alt="next"/></h3> : '' }
    { total > per_page ? <h3  style={{display: current_page !== 1 ? 'block' : 'none'}}  className="next-text" onClick={() => fetchPrevPage()}>Prev <img src={next} className="img-fluid next" alt="next"/></h3> : '' }
    <p><span className="counter-1">{from}-{to}</span>of <span className="counter-2">{total} listings</span></p>
  </div>
<div className="line"></div>
   </div>




{/* main closing div */}
</div>


    );
}

export default Listing_container;
import React from "react"
import arrow from "../../images/arrow.svg"
import google from "../../images/google.png"
import apple from "../../images/apple.svg"
import SearchForm from "./SearchForm"



const Search = (props) => {
    
 return (
<div>
    <div className="container">
    <div className="row baked-twist">

    <div className="col-md-6">
            {/* SEARCH FORM WITHOUT MODAL*/}
        <SearchForm closeModal={()=>{}} disableWrapper={false} {...props} />
    </div>

        <div className="col-md-6">
            <img src={arrow} className="img-fluid arrow-width" alt="arrow"/>
        <h3 className="dis-search-header"><span className="green">Discover</span> & buy your new <span className="green">car</span> parts.</h3>
        <p className="dis-search-body">Affordable & genuine everyday motor parts</p>
        <div className='d-flex flex-row justify-content-center justify-content-lg-start'>
        <img src={google} className="img-fluid app-logo app-logo-left" alt="google"/>
        <img src={apple} className="img-fluid app-logo ml-n5 ml-lg-0" alt="apple"/>
        </div>
       
        </div>

        
    </div>
    </div>

{/* main closing div */}
</div>
    );

 }

export default Search;
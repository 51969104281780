import React from 'react'

function Preloader() {
    return (
        <div
        style={{
          display: 'flex',
          // justifyContent: 'center',
          alignItems: 'center',
          // width: '100%',
          // height: '0vh',
        }}
      >
        <div className="spinner-grow text-success" role="status">
             <span className="sr-only"></span>
        </div>
      </div>
    )
}

export default Preloader

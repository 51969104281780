
// user
export const SIGN_UP = 'SIGN_UP'
export const LOGIN = 'LOGIN'
export const SET_USER = 'SET_USER'
export const SET_LOGOUT = 'SET_LOGOUT'
// category
export const FETCH_CATEGORIES = 'FECTH_CATEGORIES'
export const LOADING_CATEGORIES = 'LOADING_CATEGORIES'

// Listings
export const RECENT_LISTINGS = 'RECENT_LISTINGS'
export const FETCH_LISTINGS = 'FETCH_LISTINGS'
export const LOADING_LISTINGS = 'LOADING_LISTINGS'

// Ads
export const FETCH_AD = 'FETCH_AD'
export const LOADING_AD = 'LOADING_AD'

/* eslint-disable */
import React, { useState, useEffect } from "react"
import { useDispatch  } from 'react-redux'
import {  Redirect } from "react-router-dom"
import Footer from "../partials/footer"
import NavbarListingDetails from "../partials/navbar_listing_details"
import NavbarFeatures from "../partials/navbar_features"
import ListingDetailsContainer from "../partials/listing_details_container"
import { startFetchAd } from "../../redux/actions/listing"




const Listing_details = (props) => {

    const dispatch = useDispatch()
   

    const [pageNotFound, setPageNotFound] = useState(false)
    const [, setIsFetching] = useState(false)

    // eslint-disable-next-line
   const fetchAd = (slug) => {

       if(!slug || slug.length === 0) {
            setPageNotFound(true)
       }

     const slugArr =  slug.split('-')
     const id = +slugArr[slugArr.length - 1] 

       if(isNaN(id)) {
           setPageNotFound(true)
       }

    //   fetch single ad
       setIsFetching(true)

       dispatch(startFetchAd(id))
       .then(() => {
           setIsFetching(false)
       }).catch((err) => {
           setIsFetching(false)
       });
       
   }

    useEffect(() => {
        fetchAd(props.match.params.ad)
       window.scrollTo(0, 0);
    //es
   }, [])


   if(pageNotFound) {
       return <Redirect to='/404' />
   }

   return (
    <div>
        <NavbarListingDetails/>
        <NavbarFeatures/>
        <ListingDetailsContainer/>
        <Footer/>
        {/* main closing div */}

     </div>
    )


    }

export default Listing_details ;
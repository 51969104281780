import { SIGN_UP, LOGIN , SET_USER, SET_LOGOUT } from '../types'

const authDefaultState = { token: '', user: {} }

const authReducer = (state = authDefaultState, action) => {
    switch (action.type) {
        case SIGN_UP :
          return {...state, user: action.auth }
        case LOGIN: 
          return { token: action.auth.accessToken, user: action.auth.user }
      case SET_USER: 
      return { ...state, user: action.details.user }
      case SET_LOGOUT: 
      return authDefaultState
        default:
          return state;
    }
}

export { authReducer as default }
import PhoneInput from 'react-phone-input-2'


const PhoneNumberInput = ({ ...rest}) => {
    return <PhoneInput
    style={{
        marginLeft:'20px',
        marginBottom:'20px'
    }}
    inputStyle={{  
        border: "1px solid #ccc",
        outline: "none !important",
        width: "85%",
        minHeight: "4.5vh",
        height:'auto',
        color: "#717171",
        display: "block",
        marginLeft: "30px",
        marginRight: "auto",
        marginBottom: "15px",
        borderRadius: "5px",
        fontSize: "12.5px",
        padding: "15px",
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 500}} {...rest} />
}

export default PhoneNumberInput;
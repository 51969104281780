import React from "react";

export default function Request() {
    const [ status, setStatus] = React.useState("");
    // @ts-ignore
   const ContactForm = (ev) => {
        // @ts-ignore
        ev.preventDefault();
        // @ts-ignore
        const form = ev.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== XMLHttpRequest.DONE) return;
          if (xhr.status === 200) {
            form.reset();
           setStatus("SUCCESS");
          } else {
           setStatus("ERROR");
          }
        };
        xhr.send(data);
      };
    
  return (
    <div>
 

            {/* <div className="bb"> */}

            <div className="card-form-ill">
            <form
        
        // @ts-ignore
        onSubmit={ContactForm}
        action="https://formspree.io/f/xnqlbago"
        method="POST" className="row">


          
        <div className="col-lg-6 col-md-4">
            <input type="text" className="input-text-2" name='My first name is' placeholder="First name *"/>
    </div>
    <div className="col-lg-6 col-md-4">
            <input type="text" name='My second name is' className="input-text-2" placeholder="Last name *"/>
            </div>
            <div className="col-lg-6 col-md-4">
            <input type="email" name='my email is' className="input-text-2" placeholder="Business email e.g example@domain.com *"/>
            </div>
            <div className="col-lg-6 col-md-4">
            <input type="number" name='my mobile number is' className="input-text-2" placeholder="Mobile number *"/>
</div>

<div className="col-lg-6 col-md-4">
            <input type="text" name='business name' className="input-text-2" placeholder="Company name *"/>
</div>
<div className="col-lg-6 col-md-4">
            <textarea type="text" className="input-text-2" name="my request is" placeholder="Tell us anything *"></textarea>
            </div>
            <button className="btn-form">Talk to an expert</button>

            </form>

           
            {status === "SUCCESS" && <p className='small text-success mx-auto text-center mt-2'>Thanks! We will get back to you shortly</p>  }
        {status === "ERROR" && <p className='text-danger small text-center'>Ooops!There was an error,fill in fields.</p>}
        </div>

        </div>
 
    
  )
}


import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../partials/footer";
import Navbar from "../partials/navbar";
import NavAds from "../partials/nav_ads";




const Not_found = () => {
    useEffect(() => {
       window.scrollTo(0, 0);
   }, [])

   return (
       <div>

    <Navbar/>
    <NavAds/>

  <div className="container">
      <div className="row">
          <div className="col-lg-12">
              <h1 className="notfound-header">404</h1>
              <p className="notfound-body">Page not found, it seems something broke or you're missing something. </p>
              <Link to="/">
              <button className="btn-not-found">Go back to Homepage</button>
              </Link>
          </div>
      </div>
  </div>

<Footer/>
{/* main closing div */}

</div>
    )


    }

export default Not_found;
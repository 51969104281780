import React, { useEffect } from "react";


import { useAuthentication } from '../../hooks/useAuthentication';

import Footer from "../partials/footer";

import Search from "../partials/search";
import NavbarTransparent from "../partials/Navbar_Transparent_C";
import NavLoggedIn from "../partials/nav_logged_in";

import Recent from "../partials/recent";


const Listing = (props) => {

    const isAuthenticated = useAuthentication()

    useEffect(() => {
       window.scrollTo(0, 0);
   }, [])

   return (
       <div>

<div className="bac-search">
{ isAuthenticated ? <NavLoggedIn/> : <NavbarTransparent /> }
<Search {...props}/>

</div>

{/** 

<div className="container">
    <div className="row">
        <div className="col-lg-12">

<div className="bac-fancy">

<div className="container">
    <div className="row">
        <div className="col-sm-6">
        <h1 className="intro-listing-head">Still after <br/>that auto deal? </h1>
        <p className="listing-text-2">explore all possibilities between Dealers & Buyers like you're.</p>
        <Link to="/ads_mechanical">
        <button className="explore-possi">Explore Now</button>
        </Link>
        </div>
        <div className="col-sm-6">
            
            </div>
    </div>
  
</div>

</div>

        </div>
    </div>
</div>
**/}

<Recent/>

{/*
<Featured/>
*/}
<div className="line"></div>
<Footer/>
{/* main closing div */}

</div>
    )


    }

export default Listing ;

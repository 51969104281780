/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import testlogo2 from "../../images/testlogo2.png";
import { Link } from "react-router-dom";




const Navbar_Transparent_C = () => (


<div className="fixed-top">

<div className="bac-mobile">

<div className="container">
    <div className="row">
        <div className="col-lg-12">
            <Link to="/">
            <img src={testlogo2} className="img-fluid logo-nav-2" alt="logo"/>
            </Link>
        
  <button className="btn-become" id="navbarDropdown"  data-bs-toggle="dropdown" aria-expanded="false">Dealers account</button>
  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
  <Link to="/signup">
            <li><a class="dropdown-item" href="#">Create a new account</a></li>
            </Link>
            <Link to="/login">
            <li><a class="dropdown-item" href="#">Sign into existing account</a></li>
            </Link>
          </ul>  
          
            
        </div>
    </div>
</div>

</div>



{/* main closing div */}
</div>


    );

export default Navbar_Transparent_C ;
import React, { useState } from "react"
import { useDispatch } from 'react-redux'
// import { Link } from "react-router-dom"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import { startSendPasswordResetNotification } from "../../redux/actions/auth"

const Form_reset = () => {

    const dispatch = useDispatch()
    const [alertMessage, setAlertMessage] = useState({ type: 'error', message: '' })

    const validationSchema = yup.object({
        email: yup.string().trim().email('Invalid email address').required('Email address is required')
    });

 return (
 <div>
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="card-reset">
                <h3 className="form-title">Password reset</h3>
                { !!alertMessage.message
                     && 
                     <p style={{ color: alertMessage.type === 'error' ? 'red' : 'green', textAlign: 'center', fontSize:'13px', fontFamily:'Circular Std'}} >{alertMessage.message}</p>}
                   
                    <Formik
                     
                    initialValues={{ email: '' }}
                    validationSchema={validationSchema}
                    onSubmit={
                       async (values, actions) => {

                            setAlertMessage({ type: 'error', message: '' })
                            try {
                                dispatch(startSendPasswordResetNotification(values))
                                .then((result) => {
                                    if(result){
                                        values.email = ''
                                        setAlertMessage({ type: 'success', message: 'password reset link has been sent to your email'})
                                    }
                                })
                                .catch((error) => {
                                
                                    switch (error.response.status) {
                                        case 400:
                                            setAlertMessage({type: 'error', message: `${error.response.data.email}`})
                                            break;
                                        case 500:
                                            setAlertMessage({type: 'error', message: 'Oops!! an error occured, please try again later'})
                                            break;
                                    
                                        default:
                                            setAlertMessage({type: 'error', message: 'Password reset failed'})
                                            break;
                                    }
                                })

                            } catch (error) {
                                setAlertMessage({type: 'error', message:'Password reset failed'})   
                            }
                        }
                    }
                    >
                     {
                         props => (
                    
                            <Form onSubmit={props.handleSubmit}>
                                <Field type='email' name='email' className="overide input-email" placeholder="Email address e.g john@gmail.com"/>
                                <ErrorMessage name='email' component='div' className="error-handlers"/>
                                <button disabled={props.isSubmitting} className="sign-up-btn">{props.isSubmitting ? 'Reseting...' : 'Reset my password'}</button>   
                                <p className="reset">Enter the email address associated with your account and we will send you a link to reset your password.</p>
                            </Form>
                         )
                     }
                     </Formik>
                
                </div>

            </div>
        </div>
    </div>


{/* main closing div */}
</div>
)

};

export default Form_reset ;

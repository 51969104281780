import React, { useEffect } from "react";
import Footer from "../partials/footer";
import NavbarTransparency from "../partials/Navbar_Transparent_C";
import { Link } from "react-router-dom";



const Help = () => {
    useEffect(() => {
       window.scrollTo(0, 0);
   }, [])

   return (
       <div>

<div className="help-bac">
<NavbarTransparency />



<div className="container">
    <div className="row">
        <div className="col-lg-12">
            <h3 className="header-help">Help center</h3>
            <p className="header-body">A handy guide To Maine Auto Parts</p>
            <Link to="/contact">
            <button className="help-button">Contact Us</button>
          </Link>
        </div>
    </div>
</div>

</div>

<div className="container">
    <div className="row">
        <div className="col-lg-12">

<h3 className="for-buyers">For Buyers</h3>
<div className="ft-h">How can I buy something on MaineAutoParts?</div>
<p className="ft-p">To enjoy buying on MaineAutoParts, you need to follow our simple guide:</p>
<div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingOne">
      <div class="accordion-button collapsed ft-x" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
    Search for the item
      </div>
    </h2>
    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body"><p className="ft-uu">Use the search bar with filters to find what you need. We have over a million Ads, so you can choose exactly what you are looking for.</p></div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTwo">
      <div class="accordion-button collapsed ft-x"  data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
    Contact a seller
      </div>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body"><p className="ft-uu">You may contact a dealer on MaineAutoParts via mobile calls, setting up a meeting face to face, to discuss some details or negotiate about the price.</p></div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingThree">
      <div class="accordion-button collapsed ft-x"  data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
      Picking up an item
      </div>
    </h2>
    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body"><p className="ft-uu">We approve our dealers carefully, but it's always better to check what your picking or buying carefully  Meet with the seller in a public place and be sure to pay only after you get the item.</p></div>
    </div>
  </div>
</div>

<div className="space"></div>
<div className="ft-h">How can I protect myself from being scammed?</div>
<p className="ft-p">We are highly focused on security and try to solve any issues in short terms. Please, check the general tips on how to buy safely:</p>
<div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingOne1">
      <div class="accordion-button collapsed ft-x" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne">
       Meet with sellers face to face in a public place
      </div>
    </h2>
    <div id="flush-collapseOne1" class="accordion-collapse collapse" aria-labelledby="flush-headingOne1" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body"><p className="ft-uu">Check out the item's quality and make sure that it lives up your expectations before you make payment.</p></div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTwo2">
      <div class="accordion-button collapsed ft-x"  data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo2" aria-expanded="false" aria-controls="flush-collapseTwo2">
    Never give out your financial information that could be misused
      </div>
    </h2>
    <div id="flush-collapseTwo2" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo2" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body"><p className="ft-uu">Be attentive and avoid scams and frauds, such as fake payment services, fee requests or requests to use money transfer services.</p></div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingThree3">
      <div class="accordion-button collapsed ft-x"  data-bs-toggle="collapse" data-bs-target="#flush-collapseThree3" aria-expanded="false" aria-controls="flush-collapseThree3">
Be careful and check the company's background twice
      </div>
    </h2>
    <div id="flush-collapseThree3" class="accordion-collapse collapse" aria-labelledby="flush-headingThree3" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <ul>
          <li className="ft-uu">Don't submit any upfront fees for a job application;</li>
          <li className="ft-uu">Don't go to the remote or unfamiliar places for an interview;</li>
          <li className="ft-uu">Don't disclose any personal information like a bank account number or photocopy of your ID.</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingOne12">
      <div class="accordion-button collapsed ft-x" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne12" aria-expanded="false" aria-controls="flush-collapseOne">
  How can I be sure that I'll get what I requested?
      </div>
    </h2>
    <div id="flush-collapseOne12" class="accordion-collapse collapse" aria-labelledby="flush-headingOne12" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body"><p className="ft-uu">We try to check our sellers carefully, but it’s almost impossible to vouch for each of them. Expectations matter, and to avoid disappointments, we strongly recommend you meet with sellers face to face in a public place. Review the product first, and pay for it only if you are satisfied.</p></div>
    </div>
  </div>
</div>



        </div>
    </div>
</div>


<div className="container">
    <div className="row">
        <div className="col-lg-12">

<h3 className="for-buyers">General Information</h3>
<div className="ft-h">Questions & Answers</div>

<div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingOne33">
      <div class="accordion-button collapsed ft-x" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne33" aria-expanded="false" aria-controls="flush-collapseOne33">
 What is the "MaineAutoPart"?
      </div>
    </h2>
    <div id="flush-collapseOne33" class="accordion-collapse collapse" aria-labelledby="flush-headingOne33" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body"><p className="ft-uu">An online Based Marketplace for Sales of Spare part from reliable dealers In Nigeria </p></div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTwo33">
      <div class="accordion-button collapsed ft-x"  data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo33" aria-expanded="false" aria-controls="flush-collapseTwo33">
     Do you have any stores?

      </div>
    </h2>
    <div id="flush-collapseTwo33" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo33" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body"><p className="ft-uu">No, as we are not a shop. — Here you can all Automobile spare parts of all sorts, any deal you make, and you make with people. And we exist just ensure you get the best deals with reputable Spare Parts Dealers</p></div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingThree33">
      <div class="accordion-button collapsed ft-x"  data-bs-toggle="collapse" data-bs-target="#flush-collapseThree33" aria-expanded="false" aria-controls="flush-collapseThree33">
     Do you have a delivery?

      </div>
    </h2>
    <div id="flush-collapseThree33" class="accordion-collapse collapse" aria-labelledby="flush-headingThree33" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body"><p className="ft-uu">No, we don’t. But you always can discuss with the seller a comfortable way of delivering.</p></div>
    </div>
  </div>
</div>

<div className="space"></div>
<h3 className="for-buyers">Posting Ads</h3>

<div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingOne44">
      <div class="accordion-button collapsed ft-x" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne44" aria-expanded="false" aria-controls="flush-collapseOne44">
     How to post an advert on MaineAutoParts
      </div>
    </h2>
    <div id="flush-collapseOne44" class="accordion-collapse collapse" aria-labelledby="flush-headingOne44" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <p className="ft-uu">a. Click on Post Ad </p>
        <p className="ft-uu">b. Complete all the information about your item such as title, region, description, price and add photos   </p>
        <p className="ft-uu">c. After filling out the required fields, click on “Post” button. </p>
        <p className="ft-uu">d. Once you post your ad, you will be immediately redirected to our registration page (if you are not a registered Dealer). You will have to fill out all the required fields and click on ‘Register’ button at the bottom of the page. </p>
        <p className="ft-uu">e. Your advert will be published shortly once moderation process is completed. </p>
        <p className="ft-uu">f. Once your advert is live, you will receive a notification email. </p>
        <p className="ft-uu">g. Be ready to receive numerous incoming calls from your potential buyers. Good luck with your sales!</p>
        </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTwo44">
      <div class="accordion-button collapsed ft-x"  data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo44" aria-expanded="false" aria-controls="flush-collapseTwo44">
    What happens after I post my ad?
      </div>
    </h2>
    <div id="flush-collapseTwo44" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo44" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
      <p className="ft-uu">a. Our moderators will review your ad very carefully and if all the fields are filled out correctly, it will be activated shortly. </p>
        <p className="ft-uu">b. You can find your advert on our website only when it is approved by our moderators. </p>
        <p className="ft-uu">c. You will get a notification email when your advert is active. If there is something wrong with your advert, you will be notified about that and all the mistakes will be specified so that you can edit your advert correspondingly. </p>
        <p className="ft-uu">d. After publishing your advert, you can edit it again if needed and repost. Once done, your advert undergoes moderation before being activated on the website. </p>
        <p className="ft-uu">e. You can delete or close your advert any time you want. You just have to click on Close button placed under the ad or click on the cross placed on the top right corner of the ad. </p>
        <p className="ft-uu">f. You shouldn’t publish adverts of the same content. They will be considered duplicates by our moderators and won’t go active on the website. </p>
        <p className="ft-uu">g. You can always manage your ads by logging in to your MaineAutoParts account and clicking on My Adverts.</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingThree44">
      <div class="accordion-button collapsed ft-x"  data-bs-toggle="collapse" data-bs-target="#flush-collapseThree44" aria-expanded="false" aria-controls="flush-collapseThree44">
    Posting rules
      </div>
    </h2>
    <div id="flush-collapseThree44" class="accordion-collapse collapse" aria-labelledby="flush-headingThree44" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
      <p className="ft-uu"> Following rules are required while you are posting adverts on MaineAutoParts </p>
      <p className="ft-uu">a. Every advert must have a precise title. </p>
        <p className="ft-uu">b. Every advert should contain only unique images (taken by the seller and not downloaded from the Internet) without any contact information or watermarks. </p>
        <p className="ft-uu">c. Every advert must contain images. </p>
        <p className="ft-uu">d. The prices of your items must correspond to the real prices of similar products. </p>
        <p className="ft-uu">e. All posted products and/or services must be located in Nigeria. </p>
        <p className="ft-uu">f. All items and products must be legally permitted.</p>
        <p className="ft-uu">g. Each item for sale must be posted separately. You cannot post several products within one and the same advert. </p>
        <p className="ft-uu">h. Every advert must contain a brief and clear description. </p>

      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingOne48">
      <div class="accordion-button collapsed ft-x" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne48" aria-expanded="false" aria-controls="flush-collapseOne48">
    Tips for creating an effective ad
      </div>
    </h2>
    <div id="flush-collapseOne48" class="accordion-collapse collapse" aria-labelledby="flush-headingOne48" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
      <p className="ft-uu">If you really want to create a great ad, it is highly recommended to follow the instructions below: </p>

      <p className="ft-uu">a. Use a clear title which includes the name of the item you sell. Try to make your title appealing and eye-catching. </p>
        <p className="ft-uu">b. Set an appropriate price for your item so that the advert is approved. If the price is not relevant, it may be declined. Make sure to carry out some investigation of the prices for a particular item. </p>
        <p className="ft-uu">c. The description of your product must be informative enough and mustn’t contain any false information regarding your product or service. </p>
        <p className="ft-uu">d. Upload only unique and high-quality photos of your items taken by yourself and not downloaded from the Internet. The better photos you add, the more attractive your ad looks to the potential buyers and the more calls you receive. </p>
        <p className="ft-uu">e. Indicate correct contact details for the potential buyers/clients to be able to reach you easily. Try to respond all the incoming calls or to call back your customers once available.</p>
        <p className="ft-uu">f. Try to fill out all the fields of your profile page, as well as those of your advert, to let your customers dispose of all the necessary information about you as a seller and the products you sell.</p>
        <p className="ft-uu">g. The better rating you have on our website, the more chances you get to attract a lot of buyers. Remember that it is important to build trust in your business. Your rating depends on the number of positive/negative feedback received from your previous customers.</p>
        <p className="ft-uu">h. Make your advert as risk-free as possible. Underline that no prepayments are required and be ready to list those delivery services which presuppose payment on the delivery of the product ordered. </p>

      </div>
    </div>
  </div>
</div>



        </div>
    </div>
</div>




<div className="container">
    <div className="row">
        <div className="col-lg-12">

<h3 className="for-buyers">For Dealers</h3>

<div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingOne55">
      <div class="accordion-button collapsed ft-x" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne55" aria-expanded="false" aria-controls="flush-collapseOne55">
 How can I sell on MaineAutoParts?
      </div>
    </h2>
    <div id="flush-collapseOne55" class="accordion-collapse collapse" aria-labelledby="flush-headingOne55" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
      <p className="ft-uu">Post your ads on MaineAutoParts fast, just take the following steps to proceed:</p>
      <p className="ft-uu">Notes: if you are not a registered user, the first step will be registration.</p>

<p className="ft-uu">a. Click on the button "Become a Dealer"; </p>
<p className="ft-uu">b. Fill All the all the information Required; </p>
<p className="ft-uu">c. Upload your photos and write a clear title with a full description of your item. After that, enter a fair price, select attributes and send your advert to review! </p>
<p className="ft-uu"> After filling out the required fields, click on "Post Ad" button;</p>
<p className="ft-uu">When you've done all of these steps, our moderators will check your advert.</p>
<p className="ft-uu">Once your advert is live, you will receive a notification email.
If there's something wrong, we'll specify all the mistakes you should edit.
That's all you need to do!
Welcome to our great MaineAutoParts Fam!</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTwo55">
      <div class="accordion-button collapsed ft-x"  data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo55" aria-expanded="false" aria-controls="flush-collapseTwo55">
      I've posted my ad but I can't find it on MaineAutoParts. Why?

      </div>
    </h2>
    <div id="flush-collapseTwo55" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo55" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <p className="ft-uu">At first, don't worry! If you've faced such a situation, we recommend:</p>
        <p className="ft-uu">a. Wait for a while before your ad passes the moderation. It's quite possible that our moderators are checking your advert and it will appear in search results within a few hours.</p>
        <p className="ft-uu">b. Check your inbox. There you will get a notification when your advert goes live. Also, if there is something wrong with your advert, we'll specify all the mistakes you should edit.</p>
      <p className="ft-uu">Important! If you make some changes in your advert, it must pass the moderation one more time. Temporarily we won't show it in search results.</p>
     <p className="ft-uu">You can always manage your ads, just sign in to your MaineAutoParts account and click on My Adverts.</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingThree55">
      <div class="accordion-button collapsed ft-x"  data-bs-toggle="collapse" data-bs-target="#flush-collapseThree55" aria-expanded="false" aria-controls="flush-collapseThree55">
     Are there any rules concerning posting adverts on MaineAutoParts?
      </div>
    </h2>
    <div id="flush-collapseThree55" class="accordion-collapse collapse" aria-labelledby="flush-headingThree55" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
      <p className="ft-uu">a. You must add a precise title for each advert;</p>
        <p className="ft-uu">b. Make sure you add brief and clear description without any false information;</p>
        <p className="ft-uu">c. Every advert should contain only unique images (taken by you) without any contact information or watermarks.</p>
        <p className="ft-uu">d. Make sure you choose an appropriate category. Be attentive choosing a category for a job proposal or a resume. If you mention the wrong category, we can't approve your ad.</p>
        <p className="ft-uu">e. The prices of your items must correspond to the real prices of similar products.</p>
        <p className="ft-uu">f. Indicate correct contact details, so that your potential customers could reach you easily. Try to respond to all the incoming calls or to call back your customers once it's possible.</p>
        <p className="ft-uu">g. All posted products and services must be located in Nigeria.</p>
        <p className="ft-uu">h. All items and products must be legally permitted (you can check the list of prohibited items here)</p>
        <p className="ft-uu">i. Publish all items for sale separately. There is a rule: “1 ad = 1 item”.</p>
        <p className="ft-uu">j. Create different adverts. If there are identical ads we consider them as duplicates and don’t approve.</p>
      </div>
    </div>
  </div>
</div>

<div className="space"></div>

<div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingOne1">
      <div class="accordion-button collapsed ft-x" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne">
      My advert has been declined. Why?

      </div>
    </h2>
    <div id="flush-collapseOne1" class="accordion-collapse collapse" aria-labelledby="flush-headingOne1" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <p className="ft-uu"> No worries, let's figure it out together! Our moderators could decline your ad for several reasons:</p>
        <p className="ft-uu">a. You tried to post a few items within one ad. It’s not allowed on MaineAutoParts. You must post each item separately: 1 ad = 1 product.</p>
        <p className="ft-uu">b. You’ve posted pictures of nudity or images that contain contact details, watermarks, etc. We don't allow posting these kinds of photos. Please, remove them and add real photos of the item that you intend to sell. </p>
        <p className="ft-uu">c. You have set the irrelevant price for your item. Make sure to carry out some investigation of market value and input an appropriate price for your advert. </p>
        <p className="ft-uu">Follow these simple rules and our moderators will activate your ad in a short time.</p>
        </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTwo2">
      <div class="accordion-button collapsed ft-x"  data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo2" aria-expanded="false" aria-controls="flush-collapseTwo2">
      Can I share ads on social networks?
      </div>
    </h2>
    <div id="flush-collapseTwo2" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo2" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <p className="ft-uu">Sure! It helps a lot to find more clients. If you want to share the ad on social media, follow these steps:</p>
        <p className="ft-uu">a. Sign in to your MaineAutoParts account; </p>
        <p className="ft-uu">b. Go to My adverts;</p>
        <p className="ft-uu">c. Click on the advert you want to share;</p>
        <p className="ft-uu">d. Scroll down until you see Facebook/Twitter icons;</p>
        <p className="ft-uu">e. Click on a particular icon to be able to share the link.</p>

        <p className="ft-uu">That's it! Now all your friends in social networks know about the item you sell!</p>
        </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingThree3">
      <div class="accordion-button collapsed ft-x"  data-bs-toggle="collapse" data-bs-target="#flush-collapseThree3" aria-expanded="false" aria-controls="flush-collapseThree3">
      How long will my ads stay on MaineAutoParts?
      </div>
    </h2>
    <div id="flush-collapseThree3" class="accordion-collapse collapse" aria-labelledby="flush-headingThree3" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
     <p className="ft-uu">Your ads remain on the site for 1-3 months (since the date of the last renewal) before they are automatically deleted or until you decide to deactivate them.</p>
     <p className="ft-uu">Please note that you can update your ad if you haven't sold your item.</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingOne12">
      <div class="accordion-button collapsed ft-x" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne12" aria-expanded="false" aria-controls="flush-collapseOne">
      What can I do to sell better on MaineAutoParts?
      </div>
    </h2>
    <div id="flush-collapseOne12" class="accordion-collapse collapse" aria-labelledby="flush-headingOne12" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
    <p className="ft-uu">We prepared for you a few pieces of advice on how to sell like a pro:</p>
    <p className="ft-uu">a. Pay attention to the details</p>
    <p className="ft-uu">Take the relevant photos of your goods, write a clear detailed description and fill out all the fields in your profile. All of this will help your customers to learn more about you and your product.</p>

    <p className="ft-uu">b. Make your advert as risk-free, as possible</p>
    <p className="ft-uu">Remember, that you can't ask for prepayments and be ready to list those delivery services that presuppose payment after the product arrived.</p>

    <p className="ft-uu">c. Try to reply quickly to requests</p>
    <p className="ft-uu">Don't make your buyers wait for your replies too long. Be online or subscribe to Boost packages to always get SMS-notifications on new messages.</p>


    </div>
    </div>
  </div>
</div>



        </div>
    </div>

    <div className="line"></div>
</div>






<Footer/>

{/* main closing div */}
</div>
    )


    }

export default Help;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Explore from "../partials/explore";
import Footer from "../partials/footer";
import Navbar from "../partials/navbar";


const Getstarted = () => {
    useEffect(() => {
       window.scrollTo(0, 0);
   }, [])

   return (
       <div>


<Navbar/>



   

<div className="bac-fancy-2">

<div className="container">
    <div className="row">
        <div className="col-lg-12">
        <h1 className="intro-listing-head-2">Sell or buy car parts,it's quick & easy. </h1>
        <p className="listing-text">Join a vibrant community of sellers, buyers, create listings & Ads, earn money to pursue the things you love.</p>
        <Link to="/signup">
        <button className="explore-possin">Sign me up</button>
        </Link>
        </div>
      
    </div>
  
</div>

</div>

       
   



<Explore/>
{/*
<Location/>
*/}
<Footer/>

{/* main closing div */}
</div>
    )


    }

export default Getstarted;

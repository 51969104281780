import React,{ useState }  from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'

function SearchForm(props) {

    const validationSchema = yup.object({
        search: yup.string().trim().required('Search Parameter is required'),
    });

    const [brand, setBrand] = useState('')

    const onSelectBrand = (event) => {
        const selectedBrand = event.target.value
        if(brand === selectedBrand) {
            // alert(brand === selectedBrand)
         setBrand('')
        }else {
            setBrand(selectedBrand)
        }
       
    }

    return (
        <div className={ props.disableWrapper ? '' :  "search-card-bar"}>
                <h3 className="dis-search">Search for genuine auto parts</h3>

                <Formik
                initialValues={{ search: '', location: '', minimumPrice: '', maximumPrice: '' }}
                validationSchema={validationSchema}
                onSubmit={
                    (values, actions) => {
                        if(values.search){
                            let query= `?search=${values.search}`

                            if(brand) {
                                query += `&condition_id=${brand}`
                            }
                            
                            if(values.location){
                                query += `&location=${values.location}`
                            }
                            
                            if(values.minimumPrice){
                                query += `&minimumPrice=${values.minimumPrice}`
                            }
                        
                            if(values.maximumPrice){
                                query += `&maximumPrice=${values.maximumPrice}`
                            }


                            props.history.push(`/search/${values.search}${query}`)

                            if(props.disableWrapper) {
                                props.closeModal(true)
                            }
                        }
                    }
                }
                >
                    {
                        props => (
                            <Form onSubmit={props.handleSubmit}>
                                    <Field type='text' name='search' className="search-test ride" placeholder="Item name e.g fuel pump"/>
                                    <ErrorMessage name='search' component='div'  className="error-handlers-search"/>
                                    
                                    
                                    {/**start for check box */}
                                    <h3 className="dis-search">Filter by condition</h3>
                                    {/*
                                    <Field  name='location' className="search-test2 ride" type="text" placeholder="Item Location"/>
                                    */}
                                <div className="bac-filter">
                                    <div className="row">
                                   
                                        <div class="col">
                                            <label class="container-2">New (Cha Cha)
                                            <input checked={brand === '1'} onChange={(event) => onSelectBrand(event)} value='1' type="checkbox"/>
                                            <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div class="col">
                                            <label class="container-2">Used (Tokunbo)
                                            <input checked={brand === '2'} onChange={(event) => onSelectBrand(event)} value='2' type="checkbox"/>
                                            <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                        {/**end for check box */}

                                        {/**start for select */}
                                    <h3 className="dis-search">Price range</h3>
                                    <div className="container">
                                <div className="row">
                                <div class="col">
                            
                                <Field type='text' name='minimumPrice' className="ride-min" placeholder="₦ Min"/>
                                    </div>
                                <div class="col">
                                <Field type='text' name='maximumPrice' className="ride-min" placeholder="₦ Max"/>
                                </div>
                                </div>
                                </div>
                                        {/**end for select */}
                                <button  type="submit"  className="btn-search-new">Find listings & prices</button>  
                        </Form>
                        )
                    }
                </Formik>


                </div>
    )
}

export default SearchForm

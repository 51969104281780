import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../partials/footer";
import testlogo2 from "../../images/testlogo2.png";
import doodle from "../../images/doodle.svg";


const Account_success = () => {
    useEffect(() => {
       window.scrollTo(0, 0);
   }, [])

   return (
       <div>

   <div className="container">
       <div className="row">
           <div className="col-lg-12">
           <Link to="/">
            <img src={testlogo2} className="img-fluid logo-nav-2" alt="logo"/>
            </Link>
        
        <Link to="/login">
            <button className="btn-become">Log me in</button>
            </Link>

           </div>
       </div>
   </div>


<div className="container">
    <div className="row">
    <div className="col-lg-3">
        </div>
        <div className="col-lg-6">
            <img src={doodle} className="img-fluid doodle" alt="doodle"/>
<h3 className="heads-up">Heads up</h3>
<p className="heads-up-body">Your account has been created successfully, kindly log into your account to start creating your listings; Just incase you have any enquiries feel free to <br></br> <span><Link to="/help" className="guide">Read the guide here..</Link></span></p>

        </div>
        <div className="col-lg-3">
            </div>
    </div>
</div>


<Footer/>

{/* main closing div */}

</div>
    )


    }

export default Account_success ;
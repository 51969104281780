/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import AlgoliaPlaces from 'algolia-places-react';

export default (props) => {
  return (
    <AlgoliaPlaces
    defaultValue={props.defaultValue} 
      autoComplete='off'
      className={props.className}
      placeholder={ props.placeholder || 'Write an address here'}
       style={{
         width: '100% !important',
         minWidth: '100% !important',
       }}
      options={{
        // appId: '74BIM4G089',
        apiKey: '6c5b5221d60637dedcb79185a7f38c58',
        language: 'en',
        countries: ['ng'],
        // type: 'address',
        // Other options from https://community.algolia.com/places/documentation.html#options
      }}

      // onChange={({ query, rawAnswer, suggestion, suggestionIndex }) => 
      //   console.log('Fired when suggestion click.', rawAnswer.hits[0])}
      
      onChange={({ query, rawAnswer, suggestion, suggestionIndex }) => {
        console.log('Fired when suggestion selected in the dropdown or hint was validated.',suggestion);
        const data = {
          // address: `${suggestion.name}, ${suggestion.city} , ${suggestion.county}, ${suggestion.adminstrative}, ${suggestion.country}`,
          address: suggestion.value,
          city: suggestion.city,
          country: suggestion.country,
          lga: suggestion.county,
          surburb: suggestion.suburb,
          state: suggestion.administrative,
          postal_code: suggestion.postcode,
          longitude: suggestion.latlng.lng,
          latitude: suggestion.latlng.lat,
        };
        props.onSelect(data);
      
      }}

      // onSuggestions={({ rawAnswer, query, suggestions }) => 
      //   console.log('Fired when dropdown receives suggestions. You will receive the array of suggestions that are displayed.' )}

      // onCursorChanged={({ rawAnswer, query, suggestion, suggestonIndex }) => 
      //   console.log('Fired when arrows keys are used to navigate suggestions.')}

      onClear={() => {
        console.log('Fired when the input is cleared.')
        // props.onClear({})
      }}

      // onLimit={({ message }) => 
      //   console.log('Fired when you reached your current rate limit.')}

      // onError={({ message }) => 
      //   console.log('Fired when we could not make the request to Algolia Places servers for any reason but reaching your rate limit.')}
    />
  );  
}
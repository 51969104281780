import React, { useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { Link } from "react-router-dom"
import { startFetchCategories } from "../../redux/actions/category"

import drop from "../../images/drop.svg";


const Nav_ads = () => {
    

    const categories = useSelector(state => state.categories.categories)
    const dispatch = useDispatch()

    useEffect(() => {
       
        if(Array.isArray(categories) && categories.length === 0){
            dispatch(startFetchCategories())
        }

    }, [categories, dispatch])
    
    return(



<div>

<div className="nav_ads_bac">

<div className="container">
    <div className="row">
        <div className="col-lg-12">
           
        <div className="nav-item dropdown right">
          <div className="nav-link"  id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          category <img src={drop} className="img-fluid" alt="drop"/>
          </div>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            {
                categories.map(category => (
               <Link key={category.id} to={`/${category.slug}`}><li className="dropdown-item">{category.name}</li></Link> 
                )
                )
            }
           </ul>
        </div>

        </div>
    </div>
</div>

</div>



{/* main closing div */}
</div>


    );
}

export default Nav_ads ;
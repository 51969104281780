import React from "react";
import testlogo from "../../images/testlogo.png";
import { Link } from "react-router-dom";
import find from "../../images/find.svg";



const Navbar = () => (


<div className="sticky-top">

<div className="top-nav-bac">

<div className="container">
    <div className="row">
        <div className="col-lg-12">
            <Link to="/">
            <img src={testlogo} className="img-fluid logo-nav" alt="logo"/>
            </Link>
            {/*
            <Link to="/signup">
            <h6 className="min-nav-link"><img src={work} className="img-fluid ff"/>Become a Dealer</h6>
            </Link>
            */}
            <Link to="/">
            <h6 className="min-nav-link "><img src={find} className="img-fluid ff" alt="find"/>Find a Listing</h6>
            </Link>
        </div>
    </div>
</div>

</div>



{/* main closing div */}
</div>


    );

export default Navbar ;
import axios from 'axios'

import { FETCH_LISTINGS, RECENT_LISTINGS, FETCH_AD, LOADING_LISTINGS } from '../types'


const API_URL = process.env.REACT_APP_API_URL

export const fetchListings = (listings) => ({
    type: FETCH_LISTINGS,
    listings
})


export const setLoading = (loadingState) => ({
    type: LOADING_LISTINGS,
    isLoading: loadingState
})

export const startFetchListings = (query) => {
    
    return async (dispatch) => {

        dispatch(setLoading(true))

        try {
            const res = await axios.get(`${API_URL}/adverts`,{
                params: query,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })
            
            if(res.status === 200) {
                dispatch(setLoading(false))
                return dispatch(fetchListings(res.data))
            }
        } catch (error) {
            dispatch(setLoading(false))
            console.log(error)
            throw error;
            // if(error.response.statu)
        }
    }
}

export const startSearchListings = (query) => {
    
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const res = await axios.get(`${API_URL}/search-adverts${query}`,{
                // params: query,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })


            console.log(res.data)
            
            if(res.status === 200) {
                dispatch(setLoading(false))
                return dispatch(fetchListings(res.data))
            }
        } catch (error) {
            dispatch(setLoading(false))
            console.log(error)
            throw error;
            // if(error.response.statu)
        }
    }
}



export const fetchRecentListings = (listings) => ({
    type: RECENT_LISTINGS,
    listings
})

export const startFetchRecentListings = () => {
    
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const res = await axios.get(`${API_URL}/recent-adverts`,{
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })
            



            if(res.status === 200) {
                dispatch(setLoading(false))
                return dispatch(fetchRecentListings(res.data))
            }
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false))
            throw error;
            // if(error.response.statu)
        }
    }
}



export const fetchAd = (ad) =>({
    type: FETCH_AD,
    ad
})

export const startFetchAd = (id) => {

    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const res = await axios.get(`${API_URL}/adverts/${id}`,{
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })
            
            if(res.status === 200) {
                dispatch(setLoading(false))
                return dispatch(fetchAd(res.data))
            }
        } catch (error) {
            dispatch(setLoading(false))
            console.log(error)
            throw error;
            // if(error.response.statu)
        }
    }
}


import React, { useEffect } from "react";
import Footer from "../partials/footer";
import NavbarTransparent from "../partials/Navbar_Transparent_C";
import doodle from "../../images/doodle.svg";
import create from "../../images/create.svg";
import verify from "../../images/verify.svg";
import earn from "../../images/earn.svg";
import list from "../../images/list.svg";
import sell from "../../images/sell.svg";



const How = () => {
    useEffect(() => {
       window.scrollTo(0, 0);
   }, [])

   return (
       <div>

<NavbarTransparent/>

<div className="container">
    <div className="row">
        <div className="col-lg-12">
            <img src={doodle} className="img-fluid doodle" alt="doodle"/>
            <h3 className="how">How to List Autoparts just got easier.</h3>
            <p className="how-2">We go through thick and thin to make sure as a dealer your signup, list your products & make sales procedures are easy and seemless. </p>
        </div>
    </div>
</div>


<div className="container">
    <div className="row">
        <div className="col-lg-4">
        <img src={create} className="img-fluid create" alt="create"/>
            <h3 className="create-header">Create Account</h3>
            <p className="create-body">You need to Create a dealer account in order to be legible to upload your listings for sale, it's quick and easy.</p>
        </div>

        <div className="col-lg-4">


            <img src={verify} className="img-fluid create-2" alt="create"/>
            <h3 className="create-header">Get Verified</h3>
            <p className="create-body">We verify our dealers inorder to make sure for legit personalities & business is what exist on our platform.</p>

</div>

<div className="col-lg-4">
            <img src={list} className="img-fluid create-2" alt="create"/>
            <h3 className="create-header">Create Listing</h3>
            <p className="create-body">Create your listing & Ads after your signup has been completed, it's quick and easy. </p>
</div>

<div className="col-lg-4">

            <img src={earn} className="img-fluid create-3" alt="create"/>
            <h3 className="create-header">Go live & Earn</h3>
            <p className="create-body">Your Ads are only live on our platform after we verify contents uploaded by you; Don't worry you would be notified via sms or email when your Ads are live.</p>

        </div>

        <div className="col-lg-4">

            <img src={sell} className="img-fluid create-3" alt="create"/>
            <h3 className="create-header">Meet & Sell</h3>
            <p className="create-body">Always meet Buyers and unknown persons in a save enviroment for your safety.</p>

        </div>

        <div className="line"></div>
    </div>
</div>


<Footer/>

  
{/* main closing div */}

</div>
    )


    }

export default How ;
import axios from 'axios'
import { SIGN_UP, LOGIN, SET_USER, SET_LOGOUT } from '../types'

const API_URL = process.env.REACT_APP_API_URL

export const signUp = (user) => ({
    type: SIGN_UP,
    user
})

export const startSignUp = ({ first_name = '', last_name = '', email = '', phone='', password = '' }  = {}) => {

    return async(dispatch) => {

        const data = {
            first_name,
            last_name,
            email,
            phone,
            password,
            password_confirmation: password,
            is_company: 1
        }

        try {
            const res = await axios.post(`${API_URL}/register-dealer`, data, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })
            
            if(res.status === 201) {
                // console.log(res.data)
                await dispatch(signUp(res.data.data))
                return res.data.message
            }
        } catch (error) {

            throw error
            // if(error.response.statu)
        }

    }
}


export const login = (auth) => ({
    type: LOGIN,
    auth
})

export const startLogin = ({ email = '', password = '' } = {}) => {

    return async (dispatch) => {

        const data = { email, password }

        try {
            const res = await axios.post(`${API_URL}/login`, data, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })
            
            if(res.status === 200) {
                localStorage.setItem('accessToken',res.data.accessToken)
                return dispatch(login(res.data))
            }
        } catch (error) {

            throw error;
            // if(error.response.statu)
        }
    }
}

export const setuser = (details => ({
    type: SET_USER,
    details
}))

export const setLogout = (() => ({
    type: SET_LOGOUT,
  
}))

export const startSetUser = (token) => {

    return async (dispatch) => {

        try {
            const res = await axios.get(`${API_URL}/me`, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization' : `Bearer ${token}`
                }
            })
            
            if(res.status === 200) {
                
                console.log(res.data)
                return dispatch(setuser(res.data))
            }
        } catch (error) {

            throw error;
            // if(error.response.statu)
        }
    }
}

export const startSetUserDetails = (data) => {
    return async (dispatch) => {

        return dispatch(setuser(data))
    }

}


export const startSetLogout = (token) => {

    return async (dispatch) => {

        return dispatch(setLogout())
    }
}

export const startSendPasswordResetNotification = ({ email = '' } = {}) => {

    return async (dispatch) => {

        const data = { email }

        try {
            const res = await axios.post(`${API_URL}/password/email`, data, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })
            
            if(res.status === 200) {
                console.log(res.data)
                return true;
                // return dispatch(signUp())
            }
        } catch (error) {
            throw error;
            // if(error.response.statu)
        }
    }
}
export const startPasswordReset = ({ email ='' ,password = '', password_confirmation = '', token = '' } = {}) => {

    return async (dispatch) => {

        const data = { email, password, password_confirmation, token }
        
        try {
            const res = await axios.post(`${API_URL}/password/reset`, data, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })
            
            if(res.status === 200) {
                console.log(res.data)
                return true;
                // return dispatch(signUp())
            }
        } catch (error) {
            console.log(error)
            throw error;
            // if(error.response.statu)
        }
    }
}
import {useEffect} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import numeral from 'numeral'
//import { Box } from "@chakra-ui/layout";
import checo from "../../images/checo.svg";
import pending from "../../images/pending.svg";
import share from "../../images/share.svg";
import bac from "../../images/bac.svg";
import { startFetchAd } from '../../redux/actions/listing';
import { Link } from 'react-router-dom';


const Details = (props) => {

    // console.log(props)
    const dispatch = useDispatch()
    const listings = useSelector(state => state.listings)
    const {ad} = listings

    
    async function fetchAd(props) {
       await dispatch(startFetchAd(props.match.params.id))
    }

//@ts-ignore
// eslint-disable-next-line
    useEffect(() => {
        // fetch details  
       fetchAd(props)

     // eslint-disable-next-line
    }, [props])

    return (
    
        <div>

            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h4 className="t-dtt">Listing details</h4>

                       <Link to={`/dashboard/advert/edit/${props.match.params.id}`}> <button className="btn-dtt">Edit listing</button> </Link>
                    </div>
                </div>
            </div>

<div className="container">
<div className="row">
    <div className="col-lg-12">

<div className="card-details">

<div className="container">
<div class="row">
   {
       Array.isArray(ad.images) 
       &&
       ad.images.map((image, index) => {
           return (
            <div key={index} class="col-6 col-md-4">
            <img src={image} alt={'img-' + index} className="img-fluid dt-img"/>
            </div>
           )
       })
   }
</div>
   
  <div className="line"></div>


<div className="row">

<div class="col-6 col-md-4">
<h3 className="dt-tit">ITEM TITLE <br></br><span className="dt-info">{ad && ad.title}</span></h3>
    </div> 

    <div class="col-6 col-md-4">
<h3 className="dt-tit">ITEM CONDITION <br></br> <span className="dt-info">{ad && ad.condition && ad.condition.name}</span></h3>
    </div> 

    <div class="col-6 col-md-4">
    <h3 className="dt-tit">CATEGORY <br></br> <span className="dt-info">{ad &&  ad.category && ad.category.name}</span></h3>
    </div> 

    <div class="col-6 col-md-4">
    <h3 className="dt-tit">STATUS  <br></br> <span>{ad && ad.is_active === 1 ? <img src={checo} alt={ad && ad.title} className="img-fluid checo"/> : ad && ad.is_active === 0  ?  <img src={pending} alt={ad && ad.title} className="img-fluid checo"/> : '' }</span></h3>
    </div> 

    <div class="col-6 col-md-4">
    <h3 className="dt-tit">PRICE <br></br> <span className="dt-info">{`₦${numeral(ad && ad.price).format('0,0')}`}</span></h3>
    </div> 

    <div class="col-6 col-md-4">
    <h3 className="dt-tit">SHARE ADS  <br></br> 
    <a 
         href={`https://api.whatsapp.com/send?phone=&text= Hey, can you check out this ad on maine-autopart, here's is the link ${window.location.origin}/ads/${ad && ad.id}`}
         rel='noreferrer noopener'
        >
        <img src={share} className="img-fluid share-icon" alt="share"/>
        </a>
    </h3>
    </div> 

</div>

<div className="row">
<div className="col-lg-6">
<h3 className="dt-tit">ITEM LOCATION <br></br> <span className="dt-info">{ ad && ad.address && ad.address.address ? ad.address.address : 'UNKNOWN'}
</span></h3>
</div>

<div className="col-lg-12">
<h3 className="dt-tit">DESCRIPTION <br></br> <span className="dt-info">
</span></h3> 
<p>{ad && ad.description}</p>
</div>

</div>

<div className="padd-card"></div>

</div>




</div>
<img role='button' onClick={() => props.history.goBack()} src={bac} alt='' className="img-fluid bac-dtt"/>
    </div>
</div>
</div>




        </div>



    )

}

export default Details;
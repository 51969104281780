import React from "react";
import { Link } from "react-router-dom";


const Navbar_features = () => (


<div>

<div className="nav_ads_bac">
<div className="container">
    <div className="row">
        <div className="col-lg-12">
        <Link to="/">
            <h6 className="min-nav-link-22">Home</h6>
            </Link>
           
        <a href="#section1">
            <h6 className="min-nav-link-22">Details</h6>
        </a>
        <a href="#section2">
            <h6 className="min-nav-link-22">Descriptions</h6>
           </a>

          
        </div>
    </div>
</div>


</div>


{/* main closing div */}
</div>


    );

export default Navbar_features ;
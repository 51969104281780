import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import jwt_decode from 'jwt-decode';

// store
import configureStore from './redux';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { startFetchRecentListings } from './redux/actions/listing';
import { login, startSetUser } from './redux/actions/auth';

// configure store
const store = configureStore();

const jsx = (
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);


// Check if token is set
(() =>{

  // let hasAuthenticated = false

  //check local storage for token
 const token = localStorage.getItem('accessToken')

 if(typeof token !== 'undefined') {
  //  decode the token
    // const decoded = token !== null && token.length > 0 && jwt_decode(token);
    //check if token has expired
    // if(new Date().getTime() > decoded.exp) {
    //   // wipe the token in the store
    //   localStorage.setItem('accessToken', '')
    //   store.dispatch(login({ token: '' }))
    //   hasAuthenticated = true
    // }


    store.dispatch(login({ accessToken: token }))
    store.dispatch(startSetUser(token))
    // hasAuthenticated = true
 } else {
  //  if its not defined
    localStorage.setItem('accessToken', '')
    store.dispatch(login({ token: '' }))
    // hasAuthenticated = true
 }

})();


let hasRendered = false;

const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById('root'));
    hasRendered = true;
  }
};

const Preloader = (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100vh',
    }}
  >
    <div className="spinner-grow text-success" role="status">
         <span className="sr-only"></span>
    </div>
  </div>
);

ReactDOM.render(Preloader, document.getElementById('root'));

// // store dispatch recent ads
store
  .dispatch(startFetchRecentListings())
  .then(() => {
    // render the appication
    renderApp();
  })
  .catch((err) => {
    renderApp();
  });



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

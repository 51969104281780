import React, { useEffect } from "react";
import { useAuthentication } from "../../hooks/useAuthentication";
import CategoryGrid from "../partials/category_grid";
import Footer from "../partials/footer";
import NavbarTransparent from "../partials/Navbar_Transparent_C";
import NavLoggedIn from "../partials/nav_logged_in";




const Categories = () => {

    const isAuthenticated = useAuthentication()

    useEffect(() => {
       window.scrollTo(0, 0);
   }, [])

   return (
       <div>

{ isAuthenticated ? <NavLoggedIn/> : <NavbarTransparent /> }

<div className="container">
    <div className="row">
        <div className="col-lg-12">
        <h1 className="featured-listing-header">Inspiration for featured categories.</h1>
        <p className="feature-intro">With over 2,000,000 + listings, You can view & explore all listings based <br></br> on well crafted categories.</p>
        </div>
    </div>
    <div className="line"> </div>
</div>


<CategoryGrid/>

<Footer/>

  
{/* main closing div */}

</div>
    )


    }

export default Categories ;
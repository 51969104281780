import React, {useState} from 'react'
import {useFormik} from 'formik'
import * as yup from 'yup'
import axios from 'axios'
import { useToast } from '@chakra-ui/react'
// import profile from "../../images/profile.gif"
import AlgoliaSearch from '../AlgoliaSearch';
import ProfileUpload from './ProfileUpload';
import { connect } from 'react-redux'
import { startSetUser } from '../../redux/actions/auth'


const ProfileInfoPage = ({auth}) => {

    const toast = useToast()
    const [file, setFile] = useState([])
    const validationSchema = yup.object({
        company_name: yup.string().required('company name is required').trim(),
        alternate_number: yup.string().trim(),
        address: yup.string().required('addresss is required').trim(),
        city: yup.string().trim(),
        country: yup.string().trim(),
        state: yup.string().trim(),
        lga: yup.string().trim(),
        latitude: yup.string().trim(),
        longitude: yup.string().trim()
    })

   const formik = useFormik({
       enableReinitialize:true,
       initialValues: {
           company_name: auth && auth.userable && auth.userable.company_name ? auth.userable.company_name : '',
           alternative_number: auth && auth.userable && auth.userable.alternative_number ? auth.userable.alternative_number : '',
           address: auth && auth.user_address && auth.user_address.address ? auth.user_address.address : '',
           city:  auth && auth.user_address && auth.user_address.city? auth.user_address.city : '',
           country:  auth && auth.user_address && auth.user_address.country? auth.user_address.country : '',
           state:  auth && auth.user_address && auth.user_address.state ? auth.user_address.state : '',
           lga:  auth && auth.user_address && auth.user_address.lga ? auth.user_address.lga : '',
           latitude:  auth && auth.user_address && auth.user_address.latitude ? auth.user_address.latitude : '',
           longitude: auth && auth.user_address && auth.user_address.longitude ? auth.user_address.longitude : '',
       },
       validationSchema:validationSchema,
       onSubmit: async (values) => {
        const { REACT_APP_API_URL } = process.env;
           formik.setSubmitting(true)
 
           const formData = new FormData()
        try {
            for(let key in values) {
                formData.append(key, values[key])
            }
            if (file && file[0] && file[0].file){
            formData.append('avatar', file[0].file)
            };
            // Call to the server
           const response =  await axios.post(`${REACT_APP_API_URL}/update-dealer`, formData, {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'multipart/formdata'
            }
            }
           )

           if(response.status === 200 || response.status === 201) {
            toast({
                position: 'top-right',
                title: "Profile updated",
                // description: "Will be reviewed by admin",
                  status: "success",
                duration: 5000,
                isClosable: true,
            });

            formik.setSubmitting(false)
            formik.resetForm()
           }
            
        } catch (error) {
            console.log(error)
            toast({
                position: 'top-right',
                title: "Failed to update profile",
                // description: "Will be reviewed by admin",
                status: "error",
                duration: 5000,
                isClosable: true,
            });

            formik.setSubmitting(false)
        }

       }

   })


   function setGeoPlaces(geoplace) {
       for( let key in geoplace) {
           formik.setFieldValue(key, geoplace[key])
       }
   }

   function clearGeoPlaces() {
    formik.setFieldValue('address', '')
    formik.setFieldValue('city', '')
    formik.setFieldValue('country', '')
    formik.setFieldValue('state', '')
    formik.setFieldValue('lga', '')
    formik.setFieldValue('latitude', '')
    formik.setFieldValue('longitude', '')
}

    return (
 <div>

            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="acc-text-header">Let’s complete your account setup</h3>
                    </div>
                </div>
            </div>


        <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card-sign-up">
                            <h3 className="acc-form">Business information</h3>
                            <form onSubmit={formik.handleSubmit}>
                                {/* <img src={profile} className="img-fluid profile-acc" alt="profile"/>
                                <h5 className="add-acc">Upload Picture</h5> */}
                                <div id='avatar' >
                                    <ProfileUpload  file={file} setFile={setFile} />
                                </div>

                                <div>
                                <input 
                                    id='company_name' 
                                    name='company_name' 
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur} 
                                    value={formik.values.company_name}
                                    type="text" 
                                    className="overide input-text" 
                                    placeholder="Tell us your business name"
                                />
                                 {formik.errors.company_name && <div className="error-handlers">{formik.errors.company_name}</div>}
                                </div>
                                <div>
                                    <input 
                                    id='alternate_number'
                                    name='alternative_number'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.alternative_number}
                                    type="text" 
                                    className="overide input-text" 
                                    placeholder="Alternate mobile number, if any"
                                    /> 
                                </div>
                                
                                <div>
                                    <div id='profile-geoplace' className='overide input-text' style={{padding: 'unset'}}>
                                        <AlgoliaSearch 
                                        className='input-text' 
                                        onSelect={(geoplace) => setGeoPlaces(geoplace)} 
                                        onClear={() => clearGeoPlaces()}
                                        defaultValue={auth && auth.user_address && auth.user_address.address}
                                        placeholder='Business Address' 
                                        />
                                    </div>
                                    {formik.errors.address && <div className="error-handlers">{formik.errors.address} </div>}
                                </div>
                                
                                {/* <input type="text" className="overide input-text" placeholder="Business Address"/> */}
                                <input  disabled value={formik.values.city} type="text" className="overide input-text" placeholder="City"/>
                                <button type='submit' className="sign-up-btn">{formik.isSubmitting ? 'Updating...' : 'Save & update'}</button>  
                            </form>
                        </div>
                    </div>
             </div>
        </div>


        </div>



    )

}

// export default ProfileInfoPage;
const mapDispatchToProps = (dispatch) => ({
    startSetUserAction: (data) => dispatch(startSetUser(data))
   })
   
   const mapStateToProps = (state) => ({
     auth : state.auth && state.auth.user? state.auth.user : null
   })
   
   
   export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfoPage);
import React from "react"
import {  useSelector } from 'react-redux'
import { Link } from "react-router-dom"
import numeral from 'numeral'

import profile from "../../images/profile.gif"
import badge from "../../images/badge.svg"
import phone from "../../images/phone.svg"
import mmark from "../../images/mmark.svg"
import work from "../../images/work.svg"
import event from "../../images/event.svg"
import bank from "../../images/bank.svg"
//import MoreListings from "../partials/more_listings"
import location from "../../images/location.svg";
import share from "../../images/share.svg";
// import why2 from "../../images/why2.png"
// import controlw from "../../images/controlw.png"
// import controlw2 from "../../images/controlw2.png"


const Listing_details_container = () => {

    const listings = useSelector(state => state.listings)

    const { ad } = listings;

   

return (
    <div>


<div className="container">
    <div className="row">
        <div className="col-lg-12">
        <h6 className="label-tag label-tag-mobile"><img src={location} className="img-fluid icon-loco" alt="location"/>{ ad && ad.address && ad.address.address ? ad.address.address : 'UNKNOWN'}</h6>
                <h3 className="label-title label-title-mobile">{ ad && ad.title }</h3>
               {/*<Link to="/all_images">
                <h6 className="see-all">SEE ALL IMAGES</h6>
                </Link> */}
        </div>
    </div>
</div>


<div className="container mrg-image">
<div className="row">
    <div className="col-6">
        <div className="row">
        <div className="col">
        <img src={ad && ad.images ? ad.images[0] : ''} className="img-fluid rounded1" alt="img1"/>
            </div>  
        </div>
    </div>
    <div className="col-6">
    <div className="row row-cols-2">
      {
         ad && Array.isArray(ad.images)
         ?
         ad.images.map((img, index) => {
             if (index !== 0){
                 return (
                    <div key={index} className="col"><img src={img} className="img-fluid rounded2" alt="img1"/></div>
                 )
             }
             return '';
         })
         :
        //  display default image
        ''
        }
  </div>
    </div>
  </div>
</div>



<div className="container" id="section1">
    <div className="row">
        <div className="col-md-6">
<h6 className="seller-title">Listed by <span>{ ad && ad.dealer &&  ad.dealer.name ? ad.dealer.name : 'UNKNOWN' }</span></h6>
<img src={ad && ad.dealer && ad.dealer.avatar ? ad.dealer.avatar.thumb : profile} className="img-fluid rounded-circle profile" alt="profileface"/>
<h6 className="label-listing"><span className="label-listing-counter">+500</span>More listings by this dealer</h6>
{ ad && ad.dealer && ad.dealer.verified_at ? <h6 className="seller-verify"><img src={badge} className="img-fluid badge-2" alt="badge"/>Verified dealer</h6> : '' }
<h6 className="seller-verify"><img src={phone} className="img-fluid badge-2" alt="phone"/>{ ad && ad.dealer ? ad.dealer.phone : ''} {ad && ad.dealer && ad.dealer.alternative_number  ? `,${ad.dealer.alternative_number}` : ''}</h6>
{/*
<img src={phone} className="img-fluid phone" alt="phone"/>
*/}
<div className="line"></div>
<h6 className="condition"><img src={mmark} className="img-fluid mmark" alt="mark"/>Item Condition</h6>
<span className="condition-query">{ad && ad.condition ? ad.condition.name : 'UNKNOWN'}</span>

<h6 className="condition"><img src={mmark} className="img-fluid mmark" alt="mark"/>Item Price</h6>
<span className="condition-query">{`₦${numeral(ad && ad.price).format('0,0')}`}</span>
{/** 
<h6 className="condition"><img src={mmark} className="img-fluid mmark" alt="mark"/>Part Number</h6>
<span className="condition-query">4632kklsb772qq</span>
**/}

<h6 className="condition"><img src={mmark} className="img-fluid mmark" alt="mark"/>Category</h6>
<span className="condition-query">{ad && ad.category ? ad.category.name : 'UNKNOWN'}</span>

{/** 
<h6 className="condition"><img src={mmark} className="img-fluid mmark" alt="mark"/>Other Features</h6>
<span className="condition-query">null</span>
**/}
        </div>

        <div className="col-md-6">
        <h6 className="share">Share with friends</h6>
        {/* href={`https://api.whatsapp.com/send?phone=${ ad && ad.dealer ? ad.dealer.phone : ''}&text= Buy and sell your favorite auto deals | https:www.maineautoparts.com/how to get started`} */}
        <a href={`https://api.whatsapp.com/send?phone=&text= Hey, can you check out this ad on maine-autopart, here's is the link ${window.location.origin + window.location.pathname}`}
rel='noreferrer noopener'>
        <img src={share} className="img-fluid share-icon" alt="share"/>
        </a>
        <h3 className="things">Things to know</h3>
        <p className="things-2">Some few tips</p>
        <h6 className="text-tips"><img alt='' src={work} className="img-fluid work"/>Buy safely</h6>
        <h6 className="text-tips"><img alt='' src={event} className="img-fluid work-22"/>Check item condition</h6>
        <h6 className="text-tips"><img alt='' src={bank} className="img-fluid work-22"/>Make payment only when you see item(s)</h6>


</div>
    </div>
</div>


<div className="container" id="section2">
    <div className="row">
        <div className="line"></div>
        <div className="col-md-6">
            <h3 className="description-title">Item Description</h3>
            <p className="description-body" dangerouslySetInnerHTML={{__html:ad && ad.description}}></p></div>   

            <div className="col-md-6">
            <h3 className="description-title">Disclaimer</h3>
            <p className="description-body">Ensure you confirm your item before making payments
if you must meet the seller, kindly do so in a public space or verified store for a safer transaction
mainautoparts would NOT be liable for any transaction between Seller and the buyer.</p>
<Link to="/privacy">
<h6 className="read">Read full terms</h6>
</Link>
            </div>  
         </div>
         <div className="line"></div>

    </div>
  
    {/** 
<MoreListings/>
    **/}


{/* main closing div */}
</div>


    );
}

export default Listing_details_container ;
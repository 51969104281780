import { FETCH_CATEGORIES, LOADING_CATEGORIES } from '../types'

const categoriesDefaultState = { categories: [], isLoading: false }

const categoryReducer = (state = categoriesDefaultState, action) => {
    switch (action.type) {
        case FETCH_CATEGORIES :
          return {...state, categories: [...action.categories] }
        case LOADING_CATEGORIES :
          return {...state, isLoading: action.isLoading }
        default:
          return state;
    }
}

export { categoryReducer as default }
import React, { useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import { useAuthentication } from "../../hooks/useAuthentication"

import testlogo2 from "../../images/testlogo2.png"
import FormLogin from "../partials/form_login"
import face1 from "../../images/face1.png"
import face2 from "../../images/face2.png"
import face3 from "../../images/face3.png"
import face4 from "../../images/face4.png"
import face5 from "../../images/face5.png"




const Login = (props) => {

    const history = useHistory()
    const isAuthenticated = useAuthentication()
    
      useEffect(() => {
          isAuthenticated && localStorage.getItem('access_token') && history.push('/dashboard')
      }, [isAuthenticated, history])
  

    useEffect(() => {
       window.scrollTo(0, 0);
   }, [])

   return (
       <div className="bac-sign-up">

           <div className="container-fluid">

<div className="row">
    <div className="col-lg-12">
        <Link to="/getstarted">
        <img src={testlogo2} className="img-fluid logo-sign-up" alt="logo"/>
        </Link>
        <p className="aa">No account yet? <span><Link to="/signup" className="aa-colo">Sign me up</Link></span></p>

        <h3 className="sign-up-header">Welcome back, Log into your Account</h3>
    </div>
</div>


<div className="container-fluid">
    <div className="row">
        <div className="col-lg-6">
            <img src={face1} className="img-fluid face float-icons-2" alt="face1"/>
            <img src={face2} className="img-fluid face2 float-icons-3" alt="face2"/>
            <img src={face3} className="img-fluid face3 float-icons" alt="face3"/>
            <img src={face4} className="img-fluid face4 float-icons-3" alt="face4"/>
            <img src={face5} className="img-fluid face5 float-icons-2" alt="face5"/>
        </div>

        <div className="col-lg-6">
            <FormLogin {...props}/>
            </div>
    </div>
</div>




           </div>

{/* main closing div */}

</div>
    )


    }

export default Login ;

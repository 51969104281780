import React, { useEffect } from "react"
import { Link } from "react-router-dom"

import testlogo2 from "../../images/testlogo2.png"
import FormReset from "../partials/form_reset"



const Forgot_password = () => {
    useEffect(() => {
       window.scrollTo(0, 0);
   }, [])

   return (
       <div className="bac-sign-up">

           <div className="container-fluid">

<div className="row">
    <div className="col-lg-12">
        <Link to="/getstarted">
        <img src={testlogo2} className="img-fluid logo-sign-up" alt="logo"/>
        </Link>
        <p className="aa">Already have an account? <span><Link to="/login" className="aa-colo">Log me in</Link></span></p>

        <h3 className="sign-up-header">With us, selling just got easier.</h3>
    </div>
</div>

            <FormReset/>
    </div>

{/* main closing div */}

</div>
    )


    }

export default Forgot_password ;

import React,{ useState }  from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import axios from 'axios';
import AdvertUpload from '../Admin/AdvertUpload';
import PhoneNumberInput from '../CustomInputs/PhoneNumberInputs';

const {REACT_APP_API_URL} = process.env

function RequestForm(props) {
    
    const validationSchema = yup.object({
        title: yup.string().trim().required(''),
    });

    const [loading, setLoading] = useState(false)
    // const [brand, setBrand] = useState('1');
    const [ files, setFiles] = useState([]);
    // const onSelectBrand = (event) => {
    //     const selectedBrand = event.target.value
    //     setBrand(selectedBrand)
    // }

    return (
        <div className={ props.disableWrapper ? '' :  "search-card-bar"}>
             

                <Formik
                initialValues={{ title: '', description: '', name: '', email: '' , phone: ''}}
                validationSchema={validationSchema}
                onSubmit={
                 
                    async (values, actions) => {
                     const formData = new FormData();
                    setLoading(true)
                  
                        if(files.length > 0) {
                            const file_d =  files.map(file =>  {
                                return  formData.append('images[]', file.file);
                                })
                            formData.append('images', file_d)

                        }
                     formData.append('title', values.title)
                     formData.append('description', values.description)
                     formData.append('name', values.name)
                     formData.append('email', values.email)
                     formData.append('phone', values.phone)

                    try {
                            const { data } = await axios.post(REACT_APP_API_URL+ '/enquiry', formData,{
                                headers: {
                                    'Content-Type':'multipart/formdata'
                                }
                            });
                            actions.setValues({ title: '', description: '', name: '', email: '' });
                            setFiles([])
                            props.closeModal(true);
                            alert(data.message)
                            setLoading(false)
                        } catch (error) {
                            setLoading(false)
                        }
     
                    }

                }
                >
                    {
                        props => (
                            <Form onSubmit={props.handleSubmit}>

                                <h3 className="dis-search request">Request Type</h3>
                                <Field as="select" className="search-test ride" name="title">
                                    <option value="General">General</option>
                                    <option value={"I need a Car Part"}>I need a Car Part</option>
                                    <option value={"I want to be an agent"}>I want to be an agent</option>
                                    <option value="Price Check">Price Check</option>
                                    <option value="I need a mechanic">I need a mechanic</option>
                                    <option value="I want to change my car">I want to change my car</option>
                                    <option value="I need an advice">I need an advice</option>
                                </Field>
                                    <h3 className="dis-search request">Tell Us in Details what you need</h3>
                                    <Field as='textArea' type='text-area' name='description' className="search-test ride" placeholder="Item name e.g fuel pump"/>
                                    <ErrorMessage name='description' component='div'  className="error-handlers-search"/>


                                    <h3 className="dis-search request">Name</h3>
                                    <Field name='name' className="search-test ride" placeholder="Ex Femi"/>
                                    <ErrorMessage name='name' component='div'  className="error-handlers-search"/>

                                    <h3 className="dis-search request">Email Address</h3>
                                    <Field  type='email' name='email' className="search-test ride" placeholder="Ex: femoxmed@gmail.com"/>
                                    <ErrorMessage name='email' component='div'  className="error-handlers-search"/>
                                    
                                    <h3 className="dis-search request">Phone</h3>
                                    <PhoneNumberInput value={props.values.phone}  country={'ng'} className="search-test ride" onChange={val => props.setFieldValue('phone', val)} name='phone' placeholder="Ex: 90000" />
                                    <ErrorMessage name='phone' component='div'  className="error-handlers-search"/>

                                    <AdvertUpload files={files} setFiles={setFiles} title={"Upload A Supporting Document"} />
                                    
                                    {/**start for check box */}
                                    {/* <h3 className="dis-search request">Filter by condition</h3> */}
                                    {/*
                                    <Field  name='location' className="search-test2 ride" type="text" placeholder="Item Location"/>
                                    */}
                                {/* <div className="bac-filter">
                                    <div className="row">
                                        <div class="col">
                                            <label class="container-2">New (Cha Cha)
                                            <input checked={brand === '1'} onChange={(event) => onSelectBrand(event)} value='1' type="checkbox"/>
                                            <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div class="col">
                                            <label class="container-2">Used (Tokunbo)
                                            <input checked={brand === '2'} onChange={(event) => onSelectBrand(event)} value='2' type="checkbox"/>
                                            <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div> */}
                                        {/**end for check box */}

                                        {/**start for select */}
                                    {/* <h3 className="dis-search request">Price range</h3>
                                    <div className="container">
                                <div className="row">
                                <div class="col">
                            
                                <Field type='text' name='minimumPrice' className="ride-min" placeholder="₦ Min"/>
                                    </div>
                                <div class="col">
                                <Field type='text' name='maximumPrice' className="ride-min" placeholder="₦ Max"/>
                                </div>
                                </div>
                                </div> */}
                                        {/**end for select */}
                                <button disabled={loading} type="submit"  className="btn-search-new">{loading ? 'Sending' : 'Send'} Request</button>  
                        </Form>
                        )
                    }
                </Formik>


                </div>
    )
}

export default RequestForm


import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import testlogo2 from "../../images/testlogo2.png";
import Footer from "../../components/partials/footer";
import ContactForm from "../../components/partials/ContactForm";


const Contact = () => {
    useEffect(() => {
       window.scrollTo(0, 0);
   }, [])

   return (
       <div>

<div className="container-fluid">
               <div className="row">
                   <div className="col-lg-12">
                       <Link to="/">
                       <img src={testlogo2} className="img-fluid why-logo" alt="logo"/>
                       </Link>
                       <Link to="/">
                       <button className="why-button">Listings for you</button>
                       </Link>
                   </div>
               </div>
           </div>

<div className="container">
    <div className="row">
        <div className="col-lg-12">
            <h3 className="careers-mx">Need help?</h3>
            <h3 className="careers-mx-x">Contact our Sales team</h3>
            <p className="careers-t-x">Fill out this form for assistance with pricing, uploads of your listings <br></br> or any general enquiries you may have.</p>
        </div>
    </div>
</div>

<div className="container">
    <div className="row">
        <div className="col-lg-12">
        <ContactForm></ContactForm>
        </div>
    </div>
</div>

<div className="line"></div>

<Footer></Footer>


        </div>
    )
}

export default Contact;


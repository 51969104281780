import { FETCH_AD, RECENT_LISTINGS, FETCH_LISTINGS, LOADING_LISTINGS } from '../types'

const listingDefaultState = {
    current_page: 1,
    data: [],
    recent_listings:[],
    ad: {},
    per_page: 15,
    last_page: 1,
    from: 1,
    to: 1,
    total: 0,
    isLoading: false
};

const listingReducer = (state = listingDefaultState, action) => {
    
    switch (action.type) {
        case RECENT_LISTINGS:
            return {
                ...state,
                recent_listings: [...action.listings]
            }
        case FETCH_LISTINGS:
            return {
                ...state,
                ...action.listings
            }
        case LOADING_LISTINGS: 
            return {
                ...state,
                isLoading: action.isLoading
            }
        case FETCH_AD: 
            return {
                ...state,
                ad: action.ad
            }
        default:
            return state;
    }
} 

export { listingReducer as default }
import React, { useEffect, useState } from "react"
import { BrowserRouter as Router, Route, Switch , useLocation} from "react-router-dom"
import Not_found from "./components/pages/not_found";
// import Categories from "./components/pages/categories";
import Admin from "./routes/Admin";
import ClientRoutes from "./routes/Client";
import { ChakraProvider } from '@chakra-ui/react';
import theme from "./theme";
// import { useLocation } from 'react-router-dom'
import 'react-phone-input-2/lib/style.css'


const AppRender = ()=> (
  <React.Fragment>       
      <Switch>
      <Route path="/dashboard" render={(props) => { return ( <Admin {...props} />)}}/>
      <Route path="/" render={(props) => { return ( 
      <ClientRoutes {...props} />
      )}}/>

        <Route  component={Not_found}/>
        {/* path="/Not_found"*/}
        </Switch>
  </React.Fragment>
)

function AppView(props) {
  let location = useLocation()
const [ state, setState] = useState('none')
  useEffect(() => {
    if (location.pathname.includes('dashboard')){
      setState('dashboard')
    }else {
      setState('none')
    }
  }, [location]);

 
    if(state === 'dashboard') {
      return(
        <ChakraProvider resetCSS theme={theme}>
          <AppRender/>
        </ChakraProvider>
      )} else {
        return(
          <AppRender/>
        )
     
    }
  
}

function App() {
  return (
    <Router> 
      <AppView/>
    </Router>
  )
}

export default App;

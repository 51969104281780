import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import testlogo2 from "../../images/testlogo2.png"
import { startFetchCategories } from "../../redux/actions/category"
import drop from "../../images/drop.svg";



const Navbar_listing_details = () => { 
    
    const categories = useSelector(state => state.categories.categories)
    const dispatch = useDispatch()

    useEffect(() => {
       
        if(Array.isArray(categories) && categories.length === 0){
            dispatch(startFetchCategories())
        }

    }, [categories, dispatch])
    
    return (

  
<div className="sticky-top bac-white">

<div>

<div className="container">
    <div className="row">
        <div className="col-lg-12">
            <img src={testlogo2} className="img-fluid logo-nav" alt="logo"/>
           
            <div className="nav-item dropdown right-2">
          <div className="nav-link"  id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Category <img src={drop} className="img-fluid" alt="drop"/>
          </div>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
          {
            categories.map(category => (
           <Link key={category.id} to={`/${category.slug}`}><li className="dropdown-item">{category.name}</li></Link> 
            )
            )
          }
           </ul>
        </div>

           
           
        </div>
    </div>
</div>

</div>



{/* main closing div */}
</div>


    );
}

export default Navbar_listing_details ;
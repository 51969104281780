import React, { useCallback} from "react";
import { Route, Switch, Redirect, useLocation ,  useHistory} from "react-router-dom";
import AdminIndex from "../components/Admin/index";
import { Box, Flex} from "@chakra-ui/react"
import jwt_decode from 'jwt-decode'
import moment from 'moment'

// 1. Import the extendTheme function
import AdminHeader from "../components/Admin/AdminHeader";
import AdminSidebar from "../components/Admin/Sidebar";
import CreateAdvertPage from "../components/Admin/CreateAdvertPage";
import InActiveAdvert from "../components/Admin/InActiveAdvert";
// import { startLogin } from "../redux/actions/auth";
// import { connect } from "react-redux";
import ProfileInfoPage from "../components/Admin/ProfileInfoPage";
import Details from "../components/Admin/Details";
import PartRequest from "../components/Admin/PartsRequest";
// 2. Extend the theme to include custom colors, fonts, etc
// const colors = {
//   brand: {
//     900: "#1a365d",
//     800: "#153e75",
//     700: "#2a69ac",
//   },
// }
// const theme = extendTheme({ colors })

function Admin(props) {
  const router = useHistory();
//   const dispatch = useDispatch();
const authLogic = async  () => {
  const token =  localStorage.getItem('accessToken');
 
  if (token) {
    try {
            // decode the token
    
    const decoded = await jwt_decode(token);

    if ((moment().unix()/1) > +decoded.exp) {
      
    }
    else {
      // console.log('here')
      
     
    }
    } catch (error) {
      
      router.push('/login');  
    }

    // console.log((moment().unix()/1) < +decoded.exp)

}else {
  
  router.push('/login');   
}
}
const authenticate = useCallback(authLogic,[router])
const location = useLocation();
//logic for authentication
React.useEffect(()=> {

  authenticate()
  // authLogic()
 

},[authenticate, location])

 
  return (
    <React.Fragment>
     <Box w='100%' h='100%'>
    
     
        <AdminHeader />
        <Flex>

        <Box minHeight='100vh' paddingTop='50px' px='20px' width={['20%','15%']} background="#F5F5F5" d={['none', 'none', 'unset', 'unset','unset']}>
          
         
              <AdminSidebar/>
       
        </Box>
           <Box w={['100%','100%','90%', '90%','80%']} h='100%' mx='auto' pt='179px'>
              <Switch>
              <Route path="/dashboard/profile" render={(props) => <ProfileInfoPage {...props} />} />  
              <Route path="/dashboard/advert/edit/:id" render={(props) => <CreateAdvertPage {...props} />} />  
              <Route path="/dashboard/advert/:id" render={(props) => <Details {...props} />} />  
             
               <Route path="/dashboard/adverts/create" render={(props) => <CreateAdvertPage {...props}/>} />  
                <Route path="/dashboard/adverts" render={(props) => <AdminIndex {...props}/>} />
                <Route path="/dashboard/active-adverts" render={(props) =>  <AdminIndex {...props}/>} />
                <Route path="/dashboard/inactive-adverts" render={(props) => <InActiveAdvert {...props} />} />
                <Route path="/dashboard/parts-request" render={(props) => <PartRequest {...props} />} />
                <Route path="/dashboard/active-adverts" render={(props) =><></>} />
                {/* <Route path="/dashboard" render={(props) => <AdminIndex {...props}/>} /> */}
               
                <Redirect from="*/dashboard" to="/dashboard/adverts" />
              </Switch>
              </Box>
        </Flex>
       
    </Box>
        </React.Fragment>
  );
}

// const mapDispatchToProps = (dispatch) => ({
//  startLogin: (data) => dispatch(startLogin(data))
// })

// const mapStateToProps = (state) => ({
//   auth : state.auth && state.auth.token ? state.auth.token : null
// })


// export default connect(mapStateToProps, mapDispatchToProps)(Admin);
export default Admin;

import React,{Component} from 'react'
import { Link } from "react-router-dom";



class Explore extends Component{
   
    
    render(){
        return(
<div>
            <div className="container-fluid">
<div className="row">
    <div className="col-lg-12">
        <h3 className="explore-head">Explore how listing works.</h3>
        <Link to="/how">
      <button className="all-categories">Learn More</button>
      </Link>
    </div>
</div>
            </div>
            
           
       
         
       

          <div className="line"></div>
        
        </div>   
           
            );
    }
    }

    export default Explore;
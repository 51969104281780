import React from 'react'
import { Link } from 'react-router-dom'
import numeral from 'numeral'

import why2 from "../../images/why2.png";
import controlw from "../../images/controlw.png";
import controlw2 from "../../images/controlw2.png";
import event from "../../images/event.svg"


function Advert({ ad }) {
    return (
        <div className="col-6 col-md-4 d-flex flex-lg-row  flex-column">
        
          <div id={`carouselExampleInterval${ad.id}`} className="carousel slide ads-img-size" data-bs-interval="false">
            <div className="carousel-inner">
                {
                    Array.isArray(ad.images) && ad.images.length > 0
                    ?
                    ad.images.map((img, index) => (
                        <div style={{height: '135px !important'}} key={index} className={ index === 0 ? 'carousel-item active' : 'carousel-item'}>
                         <img style={{height: '135px !important'}} src={img} height='135px' className="why2" alt="img"/>
                        </div>
                    )
                    )
                    :
                    <div style={{
                        width: '150px',
                        display: 'flex',
                        background: 'white'}}  
                        
                        className={'carousel-item active' }>
                    <img src={why2} className="img-fluid why2 mx-auto" alt="img"/>
                   </div>
                }
            </div>
            { ad.images && ad.images.length > 1 && 
            
           
           <>
            <a className="carousel-control-prev" href={`#carouselExampleInterval${ad.id}`} role="button" data-bs-slide="prev">
            <img src={controlw} className="ad-select" alt="nav-icon"/>

          
            </a>
            <a className="carousel-control-next" href={`#carouselExampleInterval${ad.id}`} role="button" data-bs-slide="next">
            <img src={controlw2} className="ad-select" alt="nav-icon"/> 
            </a>
            </>
             }
            </div>
    
            <div>
            <Link to={`/ads/${ad.title.replaceAll(' ','-')}-${ad.id}`}><h3 className="ad-title">{ad.title}</h3></Link>
            <h6 className="ad-condition"><img src={event} className="img-fluid okk" alt="condition"/>{ ad.condition ? ad.condition.name : 'UNKNOWN' }</h6>
            <p className="ad-price"><span>{`₦${numeral(ad.price).format('0,0')}`}</span> /Item </p>
            </div>
           
        </div>
    )
}

export default Advert

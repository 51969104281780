import React from "react";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
// import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
// Import the plugin code
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'



// Register the plugins
registerPlugin( FilePondPluginImageResize , FilePondPluginImageTransform, FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function ProfileUpload(props) {

  return (
    <div className="App">
      <FilePond
    //   load={(source, load, error, progress, abort, headers) => {
    //     var myRequest = new Request(source);
    //     fetch(myRequest).then(function(response) {
    //       response.blob().then(function(myBlob) {
    //         load(myBlob)
    //       });
    //     });         
    // }}
        maxFiles={1}
        accepted-file-types="image/jpeg, image/png"
        imagePreviewHeight={170}
        label-idle="Upload profile picture"
        files={props.file}
        allowReorder={true}
        onupdatefiles={props.setFile}
        imageCropAspectRatio='1:1'
        imageResizeTargetWidth={200}
        imageResizeTargetHeight={200}
        stylePanelLayout='compact circle'
        styleLoadIndicatorPosition='center bottom'
        styleButtonRemoveItemPosition='center bottom'
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
    </div>
  );
}

export default ProfileUpload;




import React, { useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { startFetchCategories } from "../../redux/actions/category"
import Preloader from "./Preloader"



const CategoryGrid = () =>{

    // const [categories, setCategories] = useState([])

    const dispatch = useDispatch()
    const categories = useSelector(state => state.categories)

    useEffect(() => {
        dispatch(startFetchCategories())
    }, [dispatch])
  


  return  (
<div>

<div className="container">
<div class="row">
 
    {

        categories.isLoading
        ? 
        <div style={{ height: '30vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Preloader />
        </div>
        :
        categories.categories.length > 0 ?
        categories.categories.map(category => (
            <div key={category.id} class="col-6 col-md-4">
                {/*
            <img src={headlamp} className=" img-fluid cat-img" alt="headlamp"/>
                */}
           <Link to={`/${category.slug}`}><h4 className="cat-title">{category.name}</h4></Link>
             <p className="cat-item-total"><span>{category.count}</span> { category.count > 1 ? 'listings' : 'listing'}</p>
            </div>
            
        )
        )
        
        :

        'No Categories yet'
    }
    
    </div>
     <div className="line"> </div>
    </div>



{/* main closing div */}
</div>


    );

    }

export default CategoryGrid ;
